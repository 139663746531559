import React, { useState, useEffect } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';

const Search = ({ onSelect, setLatitude, setLongitude }) => {
    const [value, setValue] = useState(null);
    useEffect(() => {
        console.log("gggggvvvv", value)
        onSelect(value)
        if (value !== null) {
            onSelect(value.value.terms)
            geocodeByAddress(value.value.description)
                .then(results => getLatLng(results[0]))
                .then(({ lat, lng }) =>{
                    setLatitude(lat);
                    setLongitude(lng);
                    console.log('Successfully got latitude and longitude', { lat, lng })
                }
                    
                );
            console.log("gggggvvvv2", value.value.description)


        }
        //localStorage.setItem("vv",value.label)
    }, [value])
    return (
        <div>
            <GooglePlacesAutocomplete
                apiKey="AIzaSyA6KSQ1K4AYunLrJLZzibbZOAMWBSuqIPo"
                selectProps={{
                    value,
                    onChange: setValue,
                }}
            />
        </div>
    );
};

export default Search;