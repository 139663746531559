import React from 'react'
import { useHistory} from "react-router-dom";

function Header() {
    const history = useHistory();
    function handleSubmit(event) {
      event.preventDefault();
      localStorage.setItem("ISREMEMBERED", false)
      history.push('/');
    }
  
  return (  
    <header class="skin-black sidebar-mini main-header">
{/* <!-- Logo --> */}
<a href="" class="logo">
{/* <!-- mini logo for sidebar mini 50x50 pixels --> */}
<span class="logo-mini"><b>MEPCO</b></span>
{/* <!-- logo for regular state and mobile devices --> */}
<span class="logo-lg"><img src="images/logo.svg"/><span>FSM</span></span>
</a>
{/* <!-- Header Navbar: style can be found in header.less --> */}
<nav class="navbar navbar-static-top">
{/* <!-- Sidebar toggle button--> */}

<a href="#" class="sidebar-toggle" data-toggle="push-menu" role="button">
<span class="sr-only">Toggle navigation</span>
</a>
<div class="top-logo">Mepco System</div>


</nav>


</header>
  
    );
}

export default Header
