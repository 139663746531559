import { toast } from 'react-toastify'


const API_Base_Url = process.env.REACT_APP_API_BASE_URL;
var AccessToken = localStorage.getItem("AccessToken");
const loggeduserid = Number(localStorage.getItem("userid"));

//Customer List
export const LoadUser_api = async (clientid) => {
    try {
        const response = await fetch(`${API_Base_Url}users/list?clientid=${clientid}&type=all&search=`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        if (response.status === 401) {
            toast.error('Your Session has been expired, Please login again.');
            // return window.setTimeout(function () {
            //     localStorage.clear();
            //     window.location.href = "/";
            // }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            toast.error(result.errors[0])

        }
        else {

        }
    } catch (error) {
        //toast.error('Something went wrong , Please try again later.')
    }

}

export const LoadUserDetails_api = async (phone) => {
    try {
        const response = await fetch(`${API_Base_Url}users/profile?phone=${phone}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        if (response.status === 401) {
            toast.error('Your Session has been expired, Please login again.');
            // return window.setTimeout(function () {
            //     localStorage.clear();
            //     window.location.href = "/";
            // }, 1000);
        }
        const result = await response.json();
        console.log("prooooo", result)
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            toast.error(result.errors[0])


        }
        else {

        }
    } catch (error) {
        //toast.error('Something went wrong , Please try again later.')
    }

}

// Add Customers
export const CreateUser_api = async (reqdata) => {
    try {
        const response = await fetch(`${API_Base_Url}users/add`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "clientid": reqdata.clientid,
                "usertype": reqdata.usertype,
                "name": reqdata.name,
                "email": reqdata.email,
                "profilepic":reqdata.profilepic,
                "phone": reqdata.phone,
                "password": reqdata.password,
                "timezoneid": 22,
                "addedby": 0,
                "allowapplogin": false,
            })
        })
        if (response.status === 401) {
            toast.error('Your Session has been expired, Please login again.');
            // return window.setTimeout(function () {
            //     localStorage.clear();
            //     window.location.href = "/";
            // }, 1000);
        }
        const result = await response.json();
        console.log("addedddd", result);
        if (response.ok) {
            toast.success(result.message);
        }
        else if (response.status === 400) {
            toast.error(result.errors[0])
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

//Update Customer
export const UpdateUser_api = async (reqdata) => {
    try {
        const response = await fetch(`${API_Base_Url}users/update`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "userid": reqdata.userid,
                "usertype": reqdata.usertype,
                "name": reqdata.name,
                "email": reqdata.email,
                "profilepic":reqdata.profilepic,
                "phone": reqdata.phone,
                "timezoneid": 22,
                "addedby": 0,
                "allowapplogin": false,
            })
        })
        if (response.status === 401) {
            toast.error('Your Session has been expired, Please login again.');
            // return window.setTimeout(function () {
            //     localStorage.clear();
            //     window.location.href = "/";
            // }, 1000);
        }
        const result = await response.json();
        console.log("updated", result);
        if (response.ok) {
            toast.success(result.message);
        }
        else if (response.status === 400) {
            toast.error(result.errors[0])
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

//Delete Customer
export const DeleteUser_api = async (userid) => {
    try {
        const response = await fetch(`${API_Base_Url}users/delete`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "userid": userid,
                "deletedby":0
            })
        })
        if (response.status === 401) {
            toast.error('Your Session has been expired, Please login again.');
            // return window.setTimeout(function () {
            //     localStorage.clear();
            //     window.location.href = "/";
            // }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            toast.success(result.message);
        }
        else if (response.status === 400) {
            toast.error(result.errors[0])
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }

};

//Active Not Active status update
export const ChangeUserStatus_api = async (id, status) => {
    try {
        const response = await fetch(`${API_Base_Url}users/status`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "userid": id,
                "status": status,
                "updatedby":0
            })
        })
        if (response.status === 401) {
            toast.error('Your Session has been expired, Please login again.');
            // return window.setTimeout(function () {
            //     localStorage.clear();
            //     window.location.href = "/";
            // }, 1000);
        }
        const result = await response.json();
        console.log("sssssssssss",result)
        if (response.ok) {
            toast.success(result.message);
        }
        else if (response.status === 400) {
        toast.error(result.errors[0])
           
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }

};

//Customer profile 
export const Customers_Profile_api = async (customerid) => {
    try {
        const response = await fetch(`${API_Base_Url}customers/profile?customerid=${customerid}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            // return window.setTimeout(function () {
            //     localStorage.clear();
            //     window.location.href = "/";
            // }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined) {
                toast.error(result.Error.ErrorMessage)
            }
            else {

            }

        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }

}