import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
toast.configure()

function ForgotPassword() {
  const [mobile, setmobile] = useState("");
  const [password, setPassword] = useState("");


  function validateForm() {
    return mobile.length > 0;
  }
  const handleChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setmobile(value);
  };
  function handleSubmit(event) {
    event.preventDefault();
    toast.success('OTP Successfully Sent...',
           {position: toast.POSITION.TOP_CENTER,autoClose:3000})
  }

  return (

    <div class="bodybg">
    <div class="login-page ">
    <div class="login2-body ">
    <div class="loginflex"> 	
      <div class="login-left-pnl " >
      <h4 class="logo-icon"><img src="images/logo-icon.png" class="mr-10" alt="" /> Productivity Automated</h4>
      <div class="logo1">    
      <h4>Forgot Password</h4>
      </div>
     

    <div>
    <Form onSubmit={handleSubmit} className="loginform">
      <Form.Group size="lg" controlId="mobile" className="form-group">

          <Form.Control
            autoFocus
            type="mobile"
            className="form-control loignh" 
            placeholder="Mobile Number"
            value={mobile}
            maxLength={10}
            onChange={handleChange}
          />
        </Form.Group>
        {/* <Form.Group size="lg" controlId="password" className="form-group">
          <Form.Control
            type="password" 
            className="form-control loignh" 
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        
      
			 */}
	
		  	
		<div class="">
			<div class="">
      <Button block size="lg" type="submit" disabled={!validateForm()} 
      className="btn btn-green btn-block"
      
      >
          Send OTP
        </Button>
			</div>        
              
      </div>
      </Form>
    </div> 
  </div>

	<div class="login-right-pnl">
	<img src="images/login-img.png" alt=""/>
	</div>
	
	
</div>
<div class="dvlby">Developed by - Trioca Technologies Pvt Ltd <img src="images/edit.png" alt=""/></div>
</div>	
</div>

</div>


    // <div className="Login">
    //   <Form onSubmit={handleSubmit}>
    //     <Form.Group size="lg" controlId="mobile">
    //       <Form.Label>mobile</Form.Label>
    //       <Form.Control
    //         autoFocus
    //         type="mobile"
    //         value={mobile}
    //         onChange={(e) => setmobile(e.target.value)}
    //       />
    //     </Form.Group>
    //     <Form.Group size="lg" controlId="password">
    //       <Form.Label>Password</Form.Label>
    //       <Form.Control
    //         type="password"
    //         value={password}
    //         onChange={(e) => setPassword(e.target.value)}
    //       />
    //     </Form.Group>
    //     <Button block size="lg" type="submit" disabled={!validateForm()}>
    //       Login
    //     </Button>
    //   </Form>
    // </div>
  );
}

export default ForgotPassword;