import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import 'bootstrap/dist/js/bootstrap.js';
import { ChangeUserStatus_api, LoadUser_api, DeleteUser_api } from '../functions/user.js'
import { LoadClient_api } from '../functions/client.js'
import { toast } from 'react-toastify';
import Header from './wccommon/Header';
import Footer from './wccommon/Footer';
import { confirmAlert } from 'react-confirm-alert';
import Sidebar from './wccommon/sidebar';
import AddUser from "./AddUserPopup.js";

function AdminUserList() {
  const history = useHistory();
  const [userList, setuserList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [client, setClient] = useState(Number(localStorage.getItem("selectedclientid")));
  const [orgn, setOrgn] = useState(localStorage.getItem("selectedclient"));
  const [clientlist, setClientList] = useState([]);
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [number, setnumber] = useState(0);
  const [screentype,setScreentype]=useState("");
  const [selecteduser,setSelectedUser]=useState("");

  useEffect(() => {
    loadusers();
    loadusers();
  }, [number]);


  const loadusers =() => {
    setIsLoaded(false);
    LoadUser_api(client).then(res => {
      const result = res;
      if (result !== undefined) {
        if (result.data == null) {
          setuserList([])
        }
        else{
          const data = result.data;
          setuserList(data);
        }
      }
      else {
        setuserList([])
      }
      setIsLoaded(true);
    }).catch();
    console.log(userList)
  }

  const loadclients = () => {
    setIsLoaded(false);
    LoadClient_api().then(res => {
      const result = res;
      if (result !== undefined) {
        if (result.data == null) {
          setClientList([])
        }
        else
          setClientList(result.data);
      }
      else {
        setClientList([])
      }
      setIsLoaded(true);
    }).catch();
  }
  const ShowUserPopup=()=>{
    setScreentype("create");
  }

  function loadStatus(userid, status) {
    ChangeUserStatus_api(userid,status).then(res => {
      if (res !== undefined) {
        toast.success(res.message)
      }
    })
    setnumber(number+1)
  }

  const ListClients = () => {
    localStorage.setItem("screentype", "create");
    history.push("/client-list");
  }
  const loadUserDetails = (item) => {
    setScreentype("edit");
    setSelectedUser(item);
    console.log(isLoaded)
  }

  const deleteUser = (userid) => {
    confirmAlert({
      title: 'Confirmation',
      message: 'Are you sure to detete this client.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {

            DeleteUser_api(userid).then(res => {
              if (res !== undefined) {
              }
            }).catch();

            loadusers()
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  return (
    <div class="hold-transition skin-black sidebar-mini">
        <div class="wrapper" >

            {/* SideBar */}
            <Header />
          <Sidebar />
             
            {/* <!-- Content Wrapper. Contains page content --> */}
            <div class="content-wrapper db-background">

                {/* <!-- Main content --> */}
                <section class="content content-body">
                    {/* <!-- Main row --> */}
                    <div class="row">
                        <div class="col-md-12">
                            <div class="content-header ">
                                <h1 class="">
                                <Link to="/client-list"><img src="images/left-arrow.svg" alt="" style={{width:"15px"}}/></Link> Users<br></br><small>{orgn}</small>  </h1>
                                <div class="topbtn-right">
                                    <a type="" class="btn btn-theme filterbtn" data-toggle="modal" data-target="#AddNewUser" onClick={() => ShowUserPopup()}><i class="fa fa-plus-circle"></i> &nbsp;Add User</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                        <div class="top-btn-group "></div>

                            <div class="">
                                <div class="box box-solid">
                                    {/* <!-- /.box-header --> */}
                                    {
                                        isLoaded == false ? <div class="loader"><img src="images/loader.gif" alt="" /></div>
                                            :
                                            <div class="box-body table-responsive no-padding userlist">
                                                <table class="table ">
                                                    <tbody>
                                                        <tr>
                                                            <th>User Name</th>
                                                            <th>User Photo</th>
                                                            <th>User Role</th>
                                                            <th>Email</th>
                                                            <th>Phone </th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                        </tr>
                                                        {
                                                            (userList != undefined && userList != null && userList != "null") &&
                                                                userList.length == 0 ?
                                                                <tr><td colSpan="7"> No Record Found.</td></tr>
                                                                :
                                                                userList.map(item => (
                                                                    <tr>
                                                                        <td>
                                                                          

                                                                            <a style={{ cursor: 'pointer' }} data-toggle="modal" data-target="#AddNewUser" onClick={() => loadUserDetails(item.phone)} >{item.name}</a></td>
                                                                            <td>{
                                                                                    item.profilePic != "NA" && item.profilePic != ""
                                                                                    &&
                                                                                    <img src={item.profilePic} class="ass-circle" style={{borderRadius:"50%"}} />
                                                                                }</td>
                                                                        <td>{item.userType}</td>
                                                                        <td>{item.email}</td>
                                                                        <td>{item.phone}</td>
                                                                        <td class="">
                                                                            {
                                                                                item.status == "Active"
                                                                                    ?
                                                                                    <a style={{ cursor: 'pointer' }} class="stsbtn sts-active " onClick={() => loadStatus(item.userId, 0)} >Active</a>
                                                                                    :
                                                                                    <a style={{ cursor: 'pointer' }} class="stsbtn sts-inactive" onClick={() => loadStatus(item.userId, 1)}>Inactive</a>
                                                                            }

                                                                        </td>

                                                                        <td class="">
                                                                            {item.userType != "Admin" &&
                                                                                <a style={{ cursor: 'pointer' }} class="stsbtn sts-inactive" onClick={() => deleteUser(item.userId)}>Delete</a>
                                                                            }

                                                                        </td>
                                                                    </tr>
                                                                ))

                                                        }

                                                    </tbody>
                                                </table>
                                            </div>

                                    }{/* <!-- /.box-body --> */}
                                </div>
                            </div>


                        </div>
                        {/* <!-- /.row (main row) --> */}
                    </div>
                    {/* <!-- /.content --> */}
                </section>
                {/* <!-- /.content-wrapper -->       */}
            </div>

            {/* Create Form */}
            <AddUser screentype={screentype} selecteduser={selecteduser} loadusers={loadusers}/>



            {/* <!-- /.modal --> */}

        </div>
    </div>
);
}

export default AdminUserList;