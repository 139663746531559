//http://20.80.72.93/backend/api/
import React, { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { toast } from 'react-toastify';
import { LoginApi } from '../functions/auth';
//import { GetProfileApi } from '../functions/users';
import MyContext from '../contextapi/MyContext';
import { useDispatch } from 'react-redux';
import { validateEmail } from "../functions/commonfunction.js";

function Login(props) {

  const { setContextUser } = React.useContext(MyContext);

  const { Contextuser } = React.useContext(MyContext);
  const IsUserBlank = Object.keys(Contextuser).length;
  let dispatch = useDispatch();
  const history = useHistory();
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");
  const [iskeepsignIn, setKeepSignIn] = useState(true);

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  const handleChange = (e) => {
    const value = e.target.value;
    setemail(value);
  };

  const forgot = () => {
    history.push("/forgot");
  }

  const LoginBtnClick = (e) => {
    e.preventDefault();

    if (email === "") {
      return toast.error("Please enter valid email")
    }
    else if (password === "" || password === null) {
      return toast.error("Please enter password")
    }
    console.log("yes")
    getLogin();
  }

  const getLogin = async () => {

    try {

      var req = {};
      req = {
        username:email,
        password:password
      }
      console.log(req);
      LoginApi(req).then(res => {
        if (res !== undefined) {
          localStorage.setItem("AccessToken", res.accesstoken);

          console.log(res.data)

          if (iskeepsignIn) {
            localStorage.setItem("ISREMEMBERED", true)
          }
          else {
            localStorage.setItem("ISREMEMBERED", false)
          }
          setContextUser(res)
                           
          localStorage.setItem("userinfo", JSON.stringify(res.accesstoken));
          history.push("/client-list")
        }
      })
        .catch()
    } catch (error) {

    }
  }

  return (

    <div class="hold-transition login-page login2">
      {/* <div class="login-page "> */}
        <div class="login2-body ">
          {/* <div class="loginflex"> */}
            <div class="login-left-pnl " >
            <div class="logo1">
              <img src="images/logo-1.svg" class="" alt="logo-1" />
                <h4>Admin Login</h4>
              </div>


              <div>
                <Form className="loginform">
                  <Form.Group size="lg" controlId="email" className="form-group">

                    <Form.Control
                      autoFocus
                      type="text"
                      maxLength={100}
                      className="form-control loignh"
                      placeholder="Username"
                      value={email}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group size="lg" controlId="password" className="form-group">
                    <Form.Control
                      type="password"
                      className="form-control loignh"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>



                  {/* <div class="captcha">
                    <div class="spinner">
                      <label>
                        <input type="checkbox" onClick="$(this).attr('disabled', 'disabled');" />
                        <span class="checkmark"><span>&nbsp;</span></span>
                      </label>
                    </div>
                    <div class="text">
                      I'm not a robot
                    </div>
                    <div class="logo">
                      <img src="https://forum.nox.tv/core/index.php?media/9-recaptcha-png/" alt="" />
                      <p>reCAPTCHA</p>
                      <small>Privacy - Terms</small>
                    </div>
                  </div> */}

                  <div class="checkbox">
                    <label>
                    <input type="checkbox" id="html" checked={iskeepsignIn ? true : false} onChange={() => setKeepSignIn(iskeepsignIn ? false : true)} />
                    keep me signed in
                    </label>
                  </div>

                  <div class="">
                    <div class="">
                      <Link  onClick={LoginBtnClick}  block size="lg" type="submit" disabled={!validateForm()}
                        className="btn btn-green btn-block">
                        LOGIN
                      </Link>
                    </div>
                    <div class="login-footer-link">
                      {/* <span>Reset Password?</span> */}
                      <Link onClick={forgot} className="reactlink">Reset Password </Link> |
                      <Link onClick={forgot} className="reactlink">Contact Tech Support </Link>
                      
                      {/* <span class="text2">to reset</span> */}
                    </div>
                  </div>
                </Form>
              </div>
            </div>

            {/* <div class="login-right-pnl">
            <h4 class="text-center">Field Service Management System</h4>  
            <img src="images/login-bnr.png"/>
            </div> */}


          </div>
          {/* <div class="dvlby">Developed by - Trioca Technologies Pvt Ltd <img src="images/edit.png" alt="" /></div> */}
        {/* </div> */}
      </div>

    // </div>
  );
}

export default Login;