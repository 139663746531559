import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import Header from './wccommon/Header';
import Footer from './wccommon/Footer';
import { confirmAlert } from 'react-confirm-alert';
import Sidebar from './wccommon/sidebar';
import { toast } from 'react-toastify';
import ModalSubscription from './ModelSubscription';
import { LoadExpiredSubscription_api, LoadExpiredSubscriptionDetails_api } from '../functions/analytics.js'
import ModalAnalytics from './ModalAnalytics';

function ExpiredSubscription() {
  // alert(localStorage.getItem("selectedclientid"))
  const history = useHistory();
  const [Subscription, setSubscription] = useState([])
  const [isLoaded, setIsLoaded] = useState(false);
  const [offset, setOffset] = useState(0);
  const [number, setnumber] = useState(0)
  const [orgn, setOrgn] = useState(localStorage.getItem("selectedclient"));
  const [clientid, setclientid] = useState(0);

  useEffect(() => {

    if (localStorage.getItem("AccessToken") === undefined) {
      history.push("/")
    }
    FetchSubscriptionExpired()
  }, [offset, number])

  const FetchSubscriptionExpired = async () => {
    setIsLoaded(false)

    LoadExpiredSubscription_api().then(res => {
      const result = res;
      if (result !== undefined) {
        if (result.data == null) {
          setSubscription([]);
        }
        else {
          setSubscription(result.data);
        }

      }
      else {
        setSubscription(result.data);
      }
      setIsLoaded(true);
    }).catch();
  }

  console.log("Subscription", Subscription)

  const loadSubscriptionDetails = (item) => {
    localStorage.setItem("selectedclient",item)
    history.push("/client-profile")
  }


  return (
    <>
      <div class="hold-transition skin-black sidebar-mini">
        <div class="wrapper" >

          {/* SideBar */}
          <Header />
          <Sidebar />

          {/* <!-- Content Wrapper. Contains page content --> */}
          <div class="content-wrapper db-background">
            {/* <!-- Main content --> */}
            <section class="content content-body">
              {/* <!-- Main row --> */}
              <div class="row">
                <div class="col-md-12">
                  <div class="content-header">
                    <h1 class="">
                      Reports
                    </h1>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="top-btn-group estimatestab">
                    <div class="btn-group ">
                      <Link type="button" class="btn btn-default" to="/activereport">Active</Link>
                      <Link type="button" class="btn btn-theme" to="/expiredsubscriptionlist">Expired</Link>

                    </div>
                  </div>
                </div>
                {/* <!-- Left col 8--> */}
                <div class="col-lg-12">

                  <div class="top-btn-group "></div>
                  <div class="box box-solid">

                    {/* <!-- /.box-header --> */}
                    <div class="box-body table-responsive ">
                      <div class="exp-detail">
                        <b class="expddl">Expired</b>
                      </div>
                      <table class="table table-hover ticket-list">
                        <tbody><tr>
                          <th>Client Name</th>
                          <th>Contact Person Name</th>
                          <th>Contact Person Email</th>
                          <th>Contact Person Phone</th>
                          <th>Subscription Amount</th>
                          <th>Allowed Users</th>
                          <th>Expired On</th>
                        </tr>
                          {

                            isLoaded === false ? <div class="loader"><img src="images/loader.gif" alt="" /></div>
                              :
                              <>
                                {
                                  Subscription.length > 0 ?
                                    Subscription.map((data, c) => {
                                      return (
                                        <tr key={data.clientid}>
                                          <td><Link onClick={(e) => loadSubscriptionDetails(data.clientid)} class="mr-15 od-edit">{data.clientname}</Link></td>
                                          <td>{data.contactname}</td>
                                          <td>{data.contactemail}</td>
                                          <td>{data.contactphone}</td>
                                          <td>{data.planamount}</td>
                                          <td>{data.allowedusers}</td>
                                          <td>{data.expiredon}</td>
                                        </tr>
                                      )
                                    })
                                    :
                                    (
                                      <tr>
                                        <td>No Data Found</td>
                                      </tr>
                                    )
                                }
                              </>
                          }
                        </tbody></table>
                    </div>
                    {/* <!-- /.box-body --> */}

                  </div>
                </div>



              </div>
            </section>
          </div></div></div>

      {/* <!-- /.content --> */}
    </>
  )
}

export default ExpiredSubscription
