import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import Header from './wccommon/Header';
import Footer from './wccommon/Footer';
import { confirmAlert } from 'react-confirm-alert';
import Sidebar from './wccommon/sidebar';
import { toast } from 'react-toastify';
import ModalSubscription from './ModelSubscription';
import { LoadSubscription_api, LoadSubscriptionDetails_api } from '../functions/subscription.js'

function SubscriptionList() {
  // alert(localStorage.getItem("selectedclientid"))
  const history = useHistory();
  const [Subscription, setSubscription] = useState([])
  const [statusnum, setstatus] = useState(0)
  const [isLoaded, setIsLoaded] = useState(false);
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [number, setnumber] = useState(0)
  const [screentype, setScreentype] = useState("");
  const [selectedClientid, setselectedClientid] = useState(localStorage.getItem("selectedclientid"));
  const [orgn, setOrgn] = useState(localStorage.getItem("selectedclient"));

  useEffect(() => {
    if (localStorage.getItem("AccessToken") === undefined) {
      history.push("/")
    }
    console.log("Client id", localStorage.getItem("selectedclientid"));
    FetchSubscriptionDetail()
    FetchSubscriptionDetail();
  }, [offset, number])

  const FetchSubscriptionDetail = async () => {
    setIsLoaded(false)

    LoadSubscription_api(localStorage.getItem("selectedclientid")).then(res => {
      const result = res;
      if (result !== undefined) {
        if (result.data == null) {
          setSubscription([]);
        }
        else {
          setSubscription(result.data);
        }

      }
      else {
        setSubscription(result.data);
      }
      setIsLoaded(true);
    }).catch();
  }

  console.log("Subscription", Subscription)


  const AddSubscription = () => {
    setScreentype("create");
    localStorage.setItem('selectedsubscriptionid', 0);
    //window.$("#AddNewCustomer").modal('show');
  }
  const loadSubscriptionDetails = (item) => {
    setScreentype("update");
    localStorage.setItem('selectedsubscriptionid', item);
  }

  //   const updateStatus = (id, status) => {
  //     var updatedstatus=(status==="Active")?0:1;
  //     ChangeClientStatus_api(id,updatedstatus).then(res => {
  //       if (res !== undefined) {
  //           toast.success(res.message)
  //       }
  //       })
  //       .catch();
  //     setnumber(number + 1)
  //   }

  const GotoClient = () => {
    history.push('/client-list');
  }

  const GotoSubscription = (id, or) => {
    localStorage.setItem("selectedclientid", id);
    localStorage.setItem("selectedclient", or);
    history.push('/adminsubscriptionlist');

  }

  const deleteUser = (userid) => {
    confirmAlert({
      title: 'Confirmation',
      message: 'Are you sure to delete the subscription.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {

            // DeleteClient_api(userid).then(res => {
            //   if (res !== undefined) {
            //     toast.success(res.data.message);
            //   }
            // }).catch();
            history.push("/client-list")
          }
        },
        {
          label: 'No',
          onClick: () => history.push("/add-client")
        }
      ]
    });
  };

  return (
    <>
      <div className="hold-transition skin-black sidebar-mini">
        <div className="wrapper">
          <Header />
          <Sidebar />
          {/* <!-- Content Wrapper. Contains page content --> */}
          <div class="content-wrapper db-background">


            {/* <!-- Main content --> */}
            <section class="content content-body">
              {/* <!-- Main row --> */}
              <div class="row">
                <div class="col-md-12">
                  <div class="content-header ">
                    <h1 class="">
                      <Link to="/client-list"><img src="images/left-arrow.svg" alt="" style={{ width: "15px" }} /></Link> Subscriptions<br></br><small>{orgn}</small>  </h1>
                    <div class="topbtn-right">
                      <Link data-toggle="modal" data-target="#AddNewCustomer" onClick={(e) => AddSubscription()} class="btn btn-theme"><i class="fa fa-plus-circle"></i> &nbsp;Add Subscription</Link>
                    </div>
                  </div>
                </div>
                {/* <!-- Left col 8--> */}
                <div class="col-lg-12">

                <div class="top-btn-group "></div>
                  <div class="box box-solid">
                    <div class="box-header with-border">
                    </div>
                    {/* <!-- /.box-header --> */}
                    <div class="box-body">
                      <table class="table table-bordered table-striped text-center">
                        <tbody><tr>
                          <th>S. No.</th>
                          <th>Subscribed On</th>
                          <th>Amount</th>
                          <th>Allowed Users</th>
                          <th>Valid Till</th>
                          <th>Operations</th>
                        </tr>
                          {

                            isLoaded === false ? <div class="loader"><img src="images/loader.gif" alt="" /></div>
                              :
                              <>
                                {
                                  Subscription.length > 0 ?
                                    Subscription.map((data, c) => {
                                      return (
                                        <tr key={data.subscriptionid}>
                                          <td>{c+1}</td>
                                          <td>{data.createdon}</td>
                                          <td>$ {data.amount}</td>
                                          <td>{data.allowedusers}</td>
                                          <td>{data.validtilldate}</td>
                                          <td class="">
                                            <Link data-toggle="modal" data-target="#AddNewCustomer" onClick={(e) => loadSubscriptionDetails(data.subscriptionid)} class="mr-15 od-edit"><i class="fa fa-pencil"></i></Link>
                                          </td>
                                        </tr>
                                      )
                                    })
                                    :
                                    (
                                      <tr>
                                        <td colspan="6">No Data Found</td>
                                      </tr>
                                    )
                                }
                              </>
                          }
                        </tbody></table>
                    </div>
                    {/* <!-- /.box-body --> */}

                  </div>
                </div>



              </div>
              {/* <!-- /.row (main row) --> */}
            </section>
            {/* <!-- /.content --> */}

          </div>
        </div>
        <ModalSubscription screentype={screentype} FetchSubscriptionDetail={FetchSubscriptionDetail}/>
        {/* <!-- /.modal-dialog --> */}
      </div>
    </>
  )
}

export default SubscriptionList
