import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import 'bootstrap/dist/js/bootstrap.js';
import { LoadData_api } from '../functions/report.js'
import { toast } from 'react-toastify';
import Header from './wccommon/Header';
import Footer from './wccommon/Footer';
import Sidebar from './wccommon/sidebar';
import ModalAnalytics from './ModalAnalytics';

function ReportActive() {
    // alert(localStorage.getItem("selectedclientid"))
    const history = useHistory();
    const [datalist, setDataList] = useState([]);
    const [datalist1, setDataList1] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [days, setdays] = useState(7);

    useEffect(() => {
        loaddata();
    }, []);

    const loaddata = () => {
        setIsLoaded(false);
        LoadData_api(days).then(res => {
            const result = res;
            if (result !== undefined) {
                setDataList(result.expiring);
                setDataList1(result.remaining);
                console.log("eeeccccvvvgggg", result.expiring)
                console.log("eeeccccvvv", datalist)
            }
            else {
                setDataList([])
            }
            setIsLoaded(true);
        }).catch();
        console.log("eeeccccvvv1", datalist)
    }
    const loadSubscriptionDetails = (item) => {
        localStorage.setItem("selectedclient",item)
        history.push("/client-profile")
      }


    const viewClick = ()=> {
        loaddata()
        loaddata()
    }
    return (
        <>
            <div class="hold-transition skin-black sidebar-mini">
                <div class="wrapper" >

                    {/* SideBar */}
                    <Header />
                    <Sidebar />

                    {/* <!-- Content Wrapper. Contains page content --> */}
                    <div class="content-wrapper db-background">

                        {/* <!-- Main content --> */}
                        <section class="content content-body">
                            {/* <!-- Main row --> */}
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="content-header">
                                        <h1 class="">
                                            Reports
                                        </h1>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="top-btn-group estimatestab">
                                        <div class="btn-group ">
                                            <Link type="button" class="btn btn-theme" to="/activereport">Active</Link>
                                            <Link type="button" class="btn btn-default" to="/expiredsubscriptionlist">Expired</Link>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">

                                </div>
                                <div class="col-md-12">

                                    <div class="">
                                        <div class="box box-solid">
                                            {/* <!-- /.box-header --> */}

                                            <>
                                                <div class="box-body table-responsive">
                                                    <div class="exp-detail">
                                                        <div class="expddl">Expiring in next
                                                            <select value={days} onChange={(e) => setdays(e.target.value)}>
                                                                <option value="7">7</option>
                                                                <option value="15">15</option>
                                                                <option value="30">30</option>
                                                            </select>days</div>
                                                        <div class="expbtn "><Link onClick={viewClick} class="btn btn-theme" >View</Link></div>
                                                        <div style={{ clear: "both" }}></div>
                                                    </div>{
                                                        isLoaded == false ? <div class="loader"><img src="images/loader.gif" alt="" /></div>
                                                            :
                                                            <table class="table ">
                                                                <tbody>
                                                                    <tr>
                                                                        <th>Client Name</th>
                                                                        <th style={{ width: "200px" }}>Contact Person Name</th>
                                                                        <th style={{ width: "200px" }}>Contact Person Email</th>
                                                                        <th style={{ width: "200px" }}>Contact Person Phone </th>
                                                                        <th style={{ width: "200px" }}>Subsciption Amount</th>
                                                                        <th style={{ width: "200px" }}>Alloted User</th>
                                                                        <th style={{ width: "200px" }}>Expired On</th>
                                                                    </tr>
                                                                    {
                                                                        (datalist != undefined && datalist != null && datalist != "null") &&
                                                                            datalist.length == 0 ?
                                                                            <tr><td colSpan="7"> No Record Found.</td></tr>
                                                                            :
                                                                            datalist.map(item => (
                                                                                <tr>
                                                                                    <td><Link onClick={(e) => loadSubscriptionDetails(item.clientid)} class="mr-15 od-edit">{item.clientname}</Link></td>
                                                                                    <td>{item.contactname}</td>
                                                                                    <td>{item.contactemail}</td>
                                                                                    <td class="">{item.contactphone}</td>
                                                                                    <td class="">{item.planamount}</td>
                                                                                    <td class="">{item.allowedusers}</td>
                                                                                    <td>{item.expiringon}</td>
                                                                                </tr>
                                                                            ))

                                                                    }

                                                                </tbody>
                                                            </table>
                                                    }
                                                </div>
                                            </>

                                            {/* <!-- /.box-body --> */}
                                        </div>
                                        <div class="box box-solid">
                                            <div class="box-body table-responsive ">
                                                <div class="exp-detail">
                                                    <b class="expddl">Remaining</b>
                                                </div>
                                                {
                                                    isLoaded == false ? <div class="loader"><img src="images/loader.gif" alt="" /></div>
                                                        :
                                                        <table class="table table-hover ticket-list">
                                                            <tbody>
                                                                <tr>
                                                                    <th style={{ width: "200px" }}>Client Name</th>
                                                                    <th style={{ width: "200px" }}>Contact Person Name</th>
                                                                    <th style={{ width: "200px" }}>Contact Person Email</th>
                                                                    <th style={{ width: "200px" }}>Contact Person Phone </th>
                                                                    <th style={{ width: "200px" }}>Subsciption Amount</th>
                                                                    <th style={{ width: "200px" }}>Alloted User</th>
                                                                    <th style={{ width: "200px" }}>Expired On</th>
                                                                </tr>
                                                                {
                                                                    (datalist1 != undefined && datalist1 != null && datalist1 != "null") &&
                                                                        datalist1.length == 0 ?
                                                                        <tr><td colSpan="7"> No Record Found.</td></tr>
                                                                        :
                                                                        datalist1.map(item => (
                                                                            <tr>
                                                                                <td><Link onClick={(e) => loadSubscriptionDetails(item.clientid)} class="mr-15 od-edit">{item.clientname}</Link></td>
                                                                                <td>{item.contactname}</td>
                                                                                <td>{item.contactemail}</td>
                                                                                <td class="">{item.contactphone}</td>
                                                                                <td class="">{item.planamount}</td>
                                                                                <td class="">{item.allowedusers}</td>
                                                                                <td>{item.expiringon}</td>
                                                                            </tr>
                                                                        ))

                                                                }

                                                            </tbody>


                                                        </table>
                                                }
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                {/* <!-- /.row (main row) --> */}
                            </div>
                        </section>
                        {/* <!-- /.content-wrapper -->       */}
                    </div>

                    {/* <!-- /.modal --> */}

                </div>
            </div>
        </>
    )
}

export default ReportActive
