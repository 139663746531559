import React, { useState, useEffect } from 'react'
import { useHistory, Link, Redirect } from "react-router-dom";
import { toast } from 'react-toastify';
import { LoadClientDetails_api } from '../functions/client.js'
import { CreateUser_api,UpdateUser_api,LoadUserDetails_api} from "../functions/user";
import { confirmAlert } from 'react-confirm-alert';
import { validateEmail } from "../functions/commonfunction.js";
const ApiBaseURL = process.env.REACT_APP_API_BASE_URL;

function AddUser({ screentype, selecteduser, loadusers }) {

    const history = useHistory();
    let loggeduserid = localStorage.getItem("userid");
    const [selectedClientid, setselectedClientid] = useState(Number(localStorage.getItem("selectedclientid")));
    const [email, setEmail] = useState("");
    const [userType, setUserType] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [userid,setUserid]=useState("");
    const [errors, setError] = useState({});
    const [image, setImage]=useState('');

    useEffect(() => {
        if (screentype !== "create" && selecteduser!=="") {
            LoadUserDetails_api(selecteduser).then(res => {
                const result = res;
                console.log("llllllllllll",result)
                if (result !== undefined) {
                        console.log("44444444444",result)
                        setUserid(result.userId)
                        setUserType(result.userType)
                        setName(result.name)
                        setEmail(result.email)
                        setPhone(result.phone)
                } 
                else {
                }
            }).catch();
        }
    }, [selecteduser]);

    const handleValidation = () => {
        let formIsValid = true;
        let errors = {};

        if (name === "" || name === null) {
            formIsValid = false;
            errors["name"] = "Please enter name";
        }
        if (!validateEmail(email) && email !== "" && email !== null) {
            formIsValid = false;
            errors["email"] = "Please enter valid email";
        }
        if (phone !== "" && phone.length < 10 && phone !== null) {
            formIsValid = false;
            errors["phone"] = "Please enter valid mobile no.";
        }
        if (password === "" || password === null) {
            formIsValid = false;
            errors["password"] = "Please enter password.";
        }
        setError(errors);
        return formIsValid;
    }

    const handleChange = (field, e) => {
        e.preventDefault();
        if (field === "userType") {
            const value = e.target.value;
            setUserType(value);
        }
        if (field === "username") {
            const value = e.target.value;
            setName(value);
        }
        if (field === "userphone") {
            const value = e.target.value.replace(/\D/g, "");
            setPhone(value);
        }
        if (field === "useremail") {
            const value = e.target.value;
            setEmail(value);
        }
        if (field === "password") {
            const value = e.target.value;
            setPassword(value);
        }

    }

    const clearFields = () => {
        console.log("clear")
        setEmail("");
        setName("");
        setPassword("");
        setUserType("");
        setPhone("");
        errors["name"]=""
        errors["email"]=""
        errors["phone"]=""
        errors["password"]=""
    }


    const SaveBtnClick = (e) => {

        e.preventDefault();
        if (handleValidation()) {
            var req = {
                clientid: selectedClientid,
                usertype: userType,
                name: name,
                email: email,
                profilepic:image,
                phone: phone,
                password: password,
                timezoneid: 22
            }
            CreateUser_api(req).then(res => {
                if (res !== undefined) {
                    toast.success(res.message)
                    
                }
                clearFields();
                loadusers();
            })
                .catch();

        } else {
            clearFields()
            toast.error("Form has errors.")
        }
    }

    const UpdateProject_submit = (e) => {
        e.preventDefault();
        var req = {
            userid: userid,
            usertype: userType,
            name: name,
            email: email,
            profilepic:image,
            phone: phone,
            timezoneid: 22
        }
        console.log(req)
        UpdateUser_api(req).then(res => {
            if (res !== undefined) {

                toast.success(res.message);
                
            }
            clearFields();
            loadusers();
        })
            .catch();

    }
    
    const image_upload = async (e) => {

        try {
            var uploadimage = [];
            uploadimage = e.target.files;
            if (uploadimage == null)
                return alert(JSON.stringify(e));

            const formData = new FormData();
            var today = new Date();
            var doctype = "";
            const ext = uploadimage[0].name.split('.').pop();
            const datevalue = today.getDate() + today.getMonth() + today.getFullYear() + today.getHours() + today.getMinutes() + today.getSeconds();
            const filename = "profilepic_" + datevalue + "." + ext;
            if (ext == "png" || ext == "jpeg" || ext == "jpg" || ext == "PNG" || ext == "JPEG" || ext == "JPG") {
                doctype = "Image";
            }
            else
                return alert('Please select valid image.');
            formData.append("uploadedby", loggeduserid);
            formData.append("uploadedfrom", "web");
            formData.append("filename", filename);
            formData.append("file", uploadimage[0]);
            const requestOptions = {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
                },
                body: formData
            }
            const response = await fetch(ApiBaseURL + 'upload', requestOptions);
            const result = await response.json();
            if (response.ok) {
                setImage(result.filepath)
            }
            else if (response.status === 400) {
                console.log(result.errors)
                toast.error(result.errors)
            }
            else if (response.status === 401) {
                toast.error('Your Session has been expired, Please login again.');
                window.setTimeout(function () {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }, 2000);
            }
            else {

            }

        } catch (error) {
            toast.error('Something went wrong , Please try again later.')
        }

    }

    return <div className="modal fade" id="AddNewUser">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <a type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span></a>
                    {
                        screentype === "create"
                            ?
                            <h5 className="box-title">Add User</h5>
                            :
                            screentype === "edit"
                                ?
                                <h5 className="box-title">View Client</h5>
                                :
                                <h5 className="box-title">Update Client</h5>
                    }
                </div>
                <div className="modal-body">
                    <div className="add-custmer-form ">
                            <div className="row">
                                <label for="inputEmail3" className="col-sm-3 control-label"><p className="form-control flabel">UserType<span class="asterisc">*</span></p></label>
                                <div className="col-md-9">
                                <select className="col-md-9 form-control" value={userType} onChange={e => handleChange("userType",e)}>
                                    <option>User Type</option>
                                    <option value="Admin">Admin</option>
                                    <option value="Supervisor">Supervisor</option>
                                    <option value="Accounts">Accounts</option>
                                    <option value="Technician">Technician</option>
									<option value="Dispatcher">Dispatcher</option>
                                </select>
                                </div>
                                   
                            </div>

                            <div className="row">
                            <label for="inputEmail3" className="col-sm-3 control-label"><p className="form-control flabel">Name<span class="asterisc">*</span></p></label>
                            <div className="col-md-9">
                            
                                <input type="text" className="col-md-9 form-control" placeholder="Name" maxLength="20" value={name} onChange={e => handleChange("username",e)} required/>
                                <span style={{ color: "red" }}>{errors["name"]}</span>
                            </div>
                                
                            </div>
                            <div className="row">
                            <label for="inputEmail3" className="col-sm-3 control-label"><p className="form-control flabel">Contact Number<span class="asterisc">*</span></p></label>
                            <div className="col-md-9">
                            
                                <input type="text" className="col-md-9 form-control" placeholder="Phone" value={phone} maxLength="10" onChange={e => { handleChange("userphone", e) }} required/>
                                <span style={{ color: "red" }}>{errors["phone"]}</span>
                            </div>
                                
                            </div>
                            
                            <div className="row">
                                <label for="inputEmail3" className="col-sm-3 control-label"><p className="form-control flabel">Email<span class="asterisc">*</span></p></label>
                                <div className="col-md-9">
                                <input type="email" className="col-md-9 form-control" placeholder="Email" maxLength="50" value={email} onChange={e => { handleChange("useremail", e) }} required/>
                                <span style={{ color: "red" }}>{errors["email"]}</span>
                                </div>
                            </div>
                            <div class="row">
                            <label for="inputEmail3" className="col-sm-3 control-label"><p className="form-control flabel">Upload Photo</p></label>
                                <div class="col-md-9">
                                    <div class="">
                                        <input type="file" className="col-md-9 form-control" accept="image/*" id="img_profile_setting" onChange={(e) => image_upload(e)} />
                                    </div>
                                </div>
                            </div>
                                
                            {
                                screentype == "create" &&
                                    <div className="row">
                                        <label for="inputEmail3" className="col-sm-3 control-label"><p className="form-control flabel">Password<span class="asterisc">*</span></p></label>
                                        <div className="col-md-9">
                                        <input type="password" className="col-md-9 form-control" placeholder="Password" maxLength="20" value={password} onChange={e => { handleChange("password", e) }} required/>
                                        <span style={{ color: "red" }}>{errors["password"]}</span>
                                        </div>
                                    </div>
                            }

                            <div className="col-md-12">
                                <div classNameName="col-md-9">

                                </div>
                                <div classNameName="col-md-3">
                                <button data-dismiss="modal" class="btn btn-theme pull-right">Cancel</button>
                                    {
                                        screentype == "create"
                                            ?
                                            <button type="submit" className="btn btn-theme pull-right" onClick={SaveBtnClick} data-dismiss="modal">Save </button>
                                            :
                                            <button type="submit" className="btn btn-theme pull-right" onClick={UpdateProject_submit} data-dismiss="modal">Update </button>
                                    }
                                </div>


                            </div>

                            {/* {
                                screentype == "create"
                                    ?
                                    <div class="col-md-12">
                                        <a type="submit" class="btn btn-theme pull-right" onClick={createUser_submit} >Save </a>
                                    </div>

                                    :
                                    <div class="col-md-12">
                                        <a type="submit" class="btn btn-theme pull-right" onClick={UpdateUser_submit} >Update </a>
                                    </div>
                            } */}

                        </div>

                    </div>
                </div>

            </div>
            {/* <!-- /.modal-content --> */}
        </div>
}

export default AddUser
