//import './App.css';
import React from "react";
import { ToastContainer, Slide } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css"
import Login from './components/pages/Login.js';
import ReportActive from './components/admin/reportactive.js'
import VerifyEmail from "./components/admin/wccommon/verifymail.js";

//portal
import ForgotPassword from './components/pages/ForgotPassword.js';
import AdminUserList from './components/admin/admin-user-list.js';
import MyProvider from './components/contextapi/MyProvider'
import SubscriptionList from "./components/admin/Subscriptionlist.js";
import ExpiredSubscription from "./components/admin/Expired.js";
//admin
import CLientList from './components/admin/ClientList'
import ClientProfile from "./components/admin/ModalAnalytics.js";
import {
  Switch,
  Route,
  HashRouter
} from "react-router-dom";


function App() {


  return (
    <MyProvider>
    <div>
      <ToastContainer
          transition={Slide}
          position="top-center"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          limit={1}
        />
    <HashRouter>
        <Switch>
          {/* Portal Routes */}
 
          <Route exact path="/" component={Login} />
          <Route exact path="/forgot" component={ForgotPassword}/>
          <Route exact path="/adminuserlist" component={AdminUserList}/>
          <Route exact path='/verifyaccount/:verificationcode' component={VerifyEmail}/>

          {/* Admin Routes */}
          <Route exact path="/client-list" component={CLientList}/>
          <Route exact path="/adminsubscriptionlist" component={SubscriptionList}/>
          <Route exact path="/activereport" component={ReportActive}/>
          <Route exact path="/expiredsubscriptionlist" component={ExpiredSubscription}/>
          <Route exact path="/client-profile" component={ClientProfile}/>
        </Switch>
      </HashRouter>
    </div>
</MyProvider>
  );
}

export default App;
