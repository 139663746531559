import React, { useState, useEffect } from 'react'
import { useHistory, Link, Redirect } from "react-router-dom";
import { toast } from 'react-toastify';
import { LoadClientDetails_api } from '../functions/client.js'
import { confirmAlert } from 'react-confirm-alert';
import { validateEmail } from "../functions/commonfunction.js";
import { LoadSubscriptionDetails_api,CreateSubscription_api,UpdateSubscription_api } from '../functions/subscription.js';
import { format } from 'date-fns';

function ModalSubscription({ screentype, FetchSubscriptionDetail }) {
    const history = useHistory();

    // const [screentype, setScreentype] = useState(localStorage.getItem("screentype"));
    const [errors, setError] = useState({});
    const [amount,setamount] = useState("");
    const [allowedUsers,setallowedUsers] = useState("");
    const [validTill,setvalidTill] = useState("");
    const [selectedClientid, setselectedClientid] = useState(localStorage.getItem("selectedclientid"));
    var d = new Date().toLocaleDateString('en-US')
    

    useEffect(() => {
        // alert(screentype)
        if(localStorage.getItem("selectedsubscriptionid")==="0")
        {
            console.log("Enter")
            clearFields();
        }
        if (screentype !== "create" && localStorage.getItem("selectedsubscriptionid")!==0) {
            LoadSubscriptionDetails_api(localStorage.getItem("selectedclientid"),localStorage.getItem("selectedsubscriptionid")).then(res => {
                const result = res;
                if (result !== undefined) {
                    if (result == null) {
                        //setuserList([])
                    }
                    else{
                        console.log(result)
                    setamount(result.amount);
                    setallowedUsers(result.allowedusers)
                    setvalidTill(format(new Date(result.validtilldate),"yyyy-MM-dd"))
                }
                }
                else {
                }
            }).catch();
        }
    }, [localStorage.getItem("selectedsubscriptionid") ]);

    const handleValidation = () => {
        let formIsValid = true;
        let errors = {};

        if (amount === "" || amount === null) {
            formIsValid = false;
            errors["amount"] = "Please enter Amount";
        }

        if (allowedUsers === "" || allowedUsers === null) {
            formIsValid = false;
            errors["allowedUsers"] = "Please enter allowedUsers";
        }

        if (validTill === "" || validTill === null) {
            formIsValid = false;
            errors["validTIll"] = "Please enter valid till date";
        }
        if (validTill !== "" || validTill !== null) {
            var date = validTill
          
            var inpDate = new Date(date);
            var currDate = new Date();

            var dd = inpDate.getDate();
            var mm = inpDate.getMonth()+1
            var yyyy = inpDate.getFullYear();

            var dd1 = currDate.getDate();
            var mm1 = currDate.getMonth()+1
            var yyyy1 = currDate.getFullYear();

            if(yyyy === yyyy1)
            {
                if(mm === mm1)
                {
                    if(dd<=dd1)
                    {
                        alert(dd)
                        alert(dd1)
                        formIsValid = false;
                        errors["validTIll"] = "Please enter Date greater then Todays date";
                    }
                }
                else{
                    if(mm < mm1)
                    {
                        alert(mm)
                        alert(mm1)
                        formIsValid = false;
                        errors["validTIll"] = "Please enter Date greater then Todays date";
                    }
                }
            }
            else{
                if(yyyy < yyyy1)
                {
                    alert(yyyy)
                    alert(yyyy1)
                    formIsValid = false;
                    errors["validTIll"] = "Please enter Date greater then Todays date";
                }
            }
            }
        
        setError(errors);
        return formIsValid;
    }

    const clearFields = () => {
        setamount('');
        setallowedUsers('');
        setvalidTill('');
        //setselectedClientid('');
        localStorage.setItem('selectedsubscriptionid', 0);
    }

    const handleChange = (field, e) => {
        e.preventDefault();
        if (field === "amount") {
            const value = e.target.value;
            setamount(value);
        }
        if (field === "allowedUsers") {
            const value = e.target.value;
            setallowedUsers(value);
        }
        if (field === "validTill") {
            const value = e.target.value;
            setvalidTill(value);
        }
    }

    const SaveBtnClick = (e) => {

        e.preventDefault();
        if (screentype === 'edit') {
            screentype="update"
        }
        else {
            if (handleValidation()) {
                var req = {
                    clientid:Number(selectedClientid),
                    amount:Number(parseFloat(amount).toFixed(2)),
                    allowedusers:Number(allowedUsers),
                    validtilldate:validTill
                }
                toast.success(req)
                CreateSubscription_api(req).then(res => {
                    if (res !== undefined) {
                        toast.success(res.message)
                        clearFields();
                        FetchSubscriptionDetail()
                        // history.push('/client-list');
                        // <Redirect to="client-list"/>
                    }
                })
                    .catch();

            } else {
                toast.error("Add Form has errors.")
            }
        }
    }

    const UpdateProject_submit = (e) => {
        console.log("Go update")
        e.preventDefault();
        if (handleValidation()) {
            var req = {
                clientid:Number(selectedClientid),
                subscriptionid:Number(localStorage.getItem("selectedsubscriptionid")),
                amount:Number(parseFloat(amount).toFixed(2)),
                allowedusers:Number(allowedUsers),
                validtilldate:format(new Date(validTill),"yyyy-MM-dd")
            }
            UpdateSubscription_api(req).then(res => {
                if (res !== undefined) {
                    toast.success(res.message);
                    clearFields();
                    FetchSubscriptionDetail()
                }
            })
                .catch();

        } else {
            toast.error("Form has errors.")
        }
        clearFields()
    }

    return <div class="modal fade" id="AddNewCustomer">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <a type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span></a>
                    {
                        screentype === "create"
                            ?
                            <h5 class="box-title">Add Subscription</h5>
                            :
                            screentype === "edit"
                                ?
                                <h5 class="box-title">View Subscription</h5>
                                :
                                <h5 class="box-title">Update Subscription</h5>
                    }
                </div>
                <div class="modal-body">
                    <div class="add-custmer-form ">
                        <div className="row">
                            <label for="inputEmail3" class="col-sm-3 control-label"><p className="form-control flabel">Amount<span class="asterisc">*</span></p></label>
                            <div class="col-md-9">
                                
                                <p style={screentype === 'edit' ? { display: 'none' } : { display: 'block' }}><input type="number" class="form-control" step="0.01" id="inputEmail3" placeholder="Enter Amount" value={amount} maxLength="10" onChange={(e) => { handleChange("amount", e) }} style={screentype === 'edit' ? { display: 'none' } : { display: 'block' }} /></p>
                                <p className="form-control noborder" style={screentype !== 'edit' ? { display: 'none' } : { display: 'block' }}>{amount}</p>
                                <span style={{ color: "red" }}>{errors["amount"]}</span>
                            </div>
                        </div>

                        <div className="row">
                            <label for="inputEmail3" class="col-sm-3 control-label"><p className="form-control flabel">Allowed Users<span class="asterisc">*</span></p></label>
                            <div class="col-md-9">
                                <p style={screentype === 'edit' ? { display: 'none' } : { display: 'block' }}><input type="text" class="form-control" id="inputEmail3" placeholder="Enter Allowed Users" value={allowedUsers} maxLength="50" onChange={(e) => { handleChange("allowedUsers", e) }} style={screentype === 'edit' ? { display: 'none' } : { display: 'block' }} /></p>
                                <p className="form-control noborder" style={screentype !== 'edit' ? { display: 'none' } : { display: 'block' }}>{allowedUsers}</p>
                                <span style={{ color: "red" }}>{errors["allowedUsers"]}</span>
                            </div>
                        </div>

                        <div className="row">
                            <label for="inputEmail3" class="col-sm-3 control-label"><p className="form-control flabel">Valid Till<span class="asterisc">*</span></p></label>
                            <div class="col-md-9">
                                <p style={screentype === 'edit' ? { display: 'none' } : { display: 'block' }}><input type="date" id="date" class="form-control" value={validTill} id="inputEmail3" maxLength="10" onChange={(e) => { handleChange("validTill", e) }} style={screentype === 'edit' ? { display: 'none' } : { display: 'block' }} /></p>
                                <p className="form-control noborder" style={screentype !== 'edit' ? { display: 'none' } : { display: 'block' }}>{validTill}</p>
                                <span style={{ color: "red" }}>{errors["validTill"]}</span>
                            </div>
                        </div>

                        <div class="col-md-12">
                        <button class="btn btn-theme pull-right" data-dismiss="modal">Cancel</button>
                            {
                                screentype === "create"
                                    ?
                                    <button onClick={SaveBtnClick} class="btn btn-theme pull-right" data-dismiss="modal">Save</button>
                                    :
                                    screentype === "edit"
                                        ?
                                        <button onClick={SaveBtnClick} class="btn btn-theme pull-right">Edit</button>
                                        :
                                        <button onClick={UpdateProject_submit} class="btn btn-theme pull-right" data-dismiss="modal">Update</button>
                            }
                            {/* {
                    screentype === "edit"?
                    <Link onClick={(e)=>submit(selectedClientid)} href="" class="btn btntrash "><i class="fa fa-trash"></i></Link>:
                    <Link onClick={ListClients} class="btn btn-gray b-radius "> Cancel</Link>
                  } */}
                            {/* <a type="submit" class="btn btn-theme pull-right" href="#">SAVE </a> */}
                        </div>
                    </div>
                </div>

            </div>
            {/* <!-- /.modal-content --> */}
        </div>
    </div>
}

export default ModalSubscription
