import React, { useEffect, useState } from "react";
import { Link, useHistory,NavLink } from 'react-router-dom';
import { Navigation } from 'react-minimal-side-navigation';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";

function AdminSideBar() {

  const history = useHistory()
  const token = localStorage.getItem("token")
  const [loggedIn, setLoggedIn] = useState(token ? true : false)
  const [selectedtab, setTab] = useState(false);
  const [subtab, setSubTab] = useState(false);

  useEffect(() => {
  }, [])



  if (!loggedIn) {
    // history.push("/")
  }

  const handelmenu = (tab) => {



  }

  const Logout = () => {
    localStorage.clear()
    setLoggedIn(false)
    history.push("/")
  }

  return (
    <>
      <aside className="main-sidebar">
        <section className="sidebar">

          <ul class="sidebar-menu" data-widget="tree">
            <li class="">
              <NavLink activeStyle={{color: "white"}} to="/client-list">
                {/* <img src="images/activities.svg" class="menu-icon" /> */}
                <span>Clients</span>
              </NavLink>
            </li>
            <li class="">
              <NavLink activeStyle={{color: "white"}} to="/activereport">
                {/* <img src="images/activities.svg" class="menu-icon" /> */}
                <span>Reports</span>
              </NavLink>
            </li>
            <li class="treeview ">

              <Link onClick={Logout}>Logout</Link>
            </li>
            {/* <li class="treeview ">
          <a href="#">
          <img src="images/activities.svg" class="menu-icon"/>
          <span>Activities</span>
          </a>                        
          </li>
          <li class="treeview">
          <a href="#">
          <img src="images/users.svg" class="menu-icon"/>
          <span>Users</span>
          </a>
          </li>
          <li class=" treeview">
          <a href="#">
          <img src="images/reports.svg" class="menu-icon"/>
          <span>Reports</span>
          </a>
          </li>
          <li class=" treeview">
          <a href="#">
          <img src="images/settings.svg" class="menu-icon"/>
          <span>Settings</span>
          </a>
          </li> */}
          </ul>
        </section>
      </aside>



    </>

  );
}

export default AdminSideBar;