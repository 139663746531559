import { toast } from 'react-toastify'


const API_Base_Url = process.env.REACT_APP_API_BASE_URL;
var AccessToken = localStorage.getItem("AccessToken");
const loggeduserid = Number(localStorage.getItem("userid"));

//Customer List
export const LoadClient_api = async (type, role, searchtext) => {
    console.log("ttttttttt",AccessToken)
    console.log("api base url",API_Base_Url)
    try {
        const response = await fetch(API_Base_Url + `admin/clientlist`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        if (response.status === 401) {
            toast.error('Your Session has been expired, Please login again.');
            // return window.setTimeout(function () {
            //     localStorage.clear();
            //     window.location.href = "/";
            // }, 1000);
        }
        const result = await response.json();
        console.log("abc",result)
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            toast.error(result.errors[0])
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }

}

export const LoadClientDetails_api = async (id) => {
    try {
        const response = await fetch(API_Base_Url + `admin/clientdetail?clientid=${id}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })

        if (response.status === 401) {
            toast.error('Your Session has been expired, Please login again.');
            // return window.setTimeout(function () {
            //     localStorage.clear();
            //     window.location.href = "/";
            // }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            toast.error(result.errors[0])

        }
        else {

        }
    } catch (error) {
        //toast.error('Something went wrong , Please try again later.')
    }

}

// Add Customers
export const CreateClient_api = async (reqdata) => {
    try {
        const response = await fetch(`${API_Base_Url}admin/clientadd`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                organization: reqdata.organization,
                email: reqdata.email,
                contactno: reqdata.contactno,
                website: reqdata.website,
                logo: reqdata.logo,
                address: reqdata.address,
                city: reqdata.city,
                state: reqdata.state,
                country: reqdata.country,
                zipcode: reqdata.zipcode,
                contactname: reqdata.contactname,
                contactemail: reqdata.contactemail,
                contactphone: reqdata.contactphone,
                timezoneid: reqdata.timezoneid,
                latitude: reqdata.latitude,
                langitude: reqdata.langitude,
            })
        })
        if (response.status === 401) {
            toast.error('Your Session has been expired, Please login again.');
            // return window.setTimeout(function () {
            //     localStorage.clear();
            //     window.location.href = "/";
            // }, 1000);
        }
        const result = await response.json();
        console.log(result)
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            toast.error(result.errors[0])
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.' + error)
    }
};

//Update Customer
export const UpdateClient_api = async (reqdata) => {
    try {
        const response = await fetch(`${API_Base_Url}admin/clientupdate`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "clientid": reqdata.clientid,
                "organization": reqdata.organization,
                "email": reqdata.email,
                "contactno": reqdata.contactno,
                "website": reqdata.website,
                "logo": reqdata.logo,
                "address": reqdata.address,
                "city": reqdata.city,
                "state": reqdata.state,
                "country": reqdata.country,
                "zipcode": reqdata.zipcode,
                "contactname": reqdata.contactname,
                "contactemail": reqdata.contactemail,
                "contactphone": reqdata.contactphone,
                "timezoneid": 22
            })
        })
        if (response.status === 401) {
            toast.error('Your Session has been expired, Please login again.');
            // return window.setTimeout(function () {
            //     localStorage.clear();
            //     window.location.href = "/";
            // }, 1000);
        }
        const result = await response.json();
        console.log("Update result", result)
        if (response.ok) {
            return result
        }
        else if (response.status === 400) {
            toast.error(result.errors[0])
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

//Delete Customer
export const DeleteClient_api = async (id) => {
    try {
        const response = await fetch(`${API_Base_Url}admin/clientdelete`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "clientid": id
            })
        })
        if (response.status === 401) {
            toast.error('Your Session has been expired, Please login again.');
            // return window.setTimeout(function () {
            //     localStorage.clear();
            //     window.location.href = "/";
            // }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            toast.success(result.message)
        }
        else if (response.status === 400) {
            toast.error(result.errors[0])
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }

};

//Active Not Active status update
export const ChangeClientStatus_api = async (id, status) => {
    try {
        const response = await fetch(`${API_Base_Url}admin/clientstatus`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "clientid": id,
                "status": status
            })
        })
        if (response.status === 401) {
            toast.error('Your Session has been expired, Please login again.');
            // return window.setTimeout(function () {
            //     localStorage.clear();
            //     window.location.href = "/";
            // }, 1000);
        }
        const result = await response.json();
        console.log("sssssss", result)
        if (response.ok) {
            toast.success(result.message)
        }
        else if (response.status === 400) {
            toast.error(result.errors[0])
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.' + error)
    }

};