import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
// import Sidebar from './sidebar';
// import Header from './Header';
import { VerifyEmail_api } from '../../functions/auth.js'

function VerifyEmail() {
    const history = useHistory();
    const { verificationcode } = useParams();
    const [message, setMessage] = useState("");

    useEffect(() => {
        VerifyEmail_api(verificationcode).then(res => {
            if (res !== undefined) {
                setMessage(res)
            }
        })
            .catch();
    }, [verificationcode])


    return (
        <div class="">
            {/* <div class="wrapper" > */}

                {/* SideBar */}
                {/* <Header />
                <Sidebar /> */}
                <div class="content-wrapper db-background" style={{margin:0}}>
                    <section class="content content-body">
                        {/* <!-- Main row --> */}
                        <div class="row">
                            {/* <!-- Left col 8--> */}
                            <div class="col-lg-12">
                                <div class="content-header ">
                                    <h1 class="">&nbsp;</h1>
                                    <div class="topbtn-right">
                                        &nbsp;
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="top-btn-group "></div>
                                <div class="box box-solid">
                                    <div class="box-body" style={{padding: "50px 0",}}>
                                            <p style={{textAlign:"center", fontSize:"20px", fontWeight:600}}>{message} !</p>
                                            <p style={{textAlign:"center" , marginTop:"30px"}}><a href="http://portal.mepco.services/#/" class="btn btn-theme">LOGIN</a></p>
                                    </div>
                                    {/* <!-- /.box-body --> */}

                                </div>
                            </div>



                        </div>
                        {/* <!-- /.row (main row) --> */}
                    </section>
                </div>
            {/* </div> */}
        </div>
    );
}

export default VerifyEmail;