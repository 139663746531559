import React, { useState, useEffect } from 'react'
import { useHistory, Link, Redirect } from "react-router-dom";
import { toast } from 'react-toastify';
import { LoadClientDetails_api } from '../functions/client.js'
import { CreateClient_api, UpdateClient_api, DeleteClient_api } from "../functions/client";
import { confirmAlert } from 'react-confirm-alert';
import { validateEmail } from "../functions/commonfunction.js";
import Search from './searchgoogleapi.js';

function ModalComponent({ screentype, FetchClientDetail }) {

    const history = useHistory();
    const [errors, setError] = useState({});
    const [Clientname, setClientname] = useState("");
    const [contactperson, setcontactperson] = useState("");
    const [contactemail, setcontactemail] = useState("");
    const [contactphone, setcontactphone] = useState("");
    const [website, setwebsite] = useState("");
    const [logo, setlogo] = useState("");
    const [address, setaddress] = useState("");
    const [city, setcity] = useState("");
    const [state, setstate] = useState("");
    const [country, setcountry] = useState("");
    const [zipcode, setzipcode] = useState("");
    const [personname, setpersonname] = useState("");
    const [personemail, setpersonemail] = useState("");
    const [personphone, setpersonphone] = useState("");
    const [selectedClientid, setselectedClientid] = useState(localStorage.getItem("selectedclientid"));
    const [location, setLocation] = useState("");
    const [latitude, setLatitude] = useState("");
    const [langitude, setLongitude] = useState("");
    const [value, onSelect] = useState([]);

    // const script = document.createElement("script");

    // script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyA6KSQ1K4AYunLrJLZzibbZOAMWBSuqIPo&libraries=places&callback=initMap";
    // script.async = true;

    // document.body.appendChild(script);

    useEffect(() => {
        console.log("vvvvvvvv", value)
        if (value !== null && value.length > 0) {
            setstate(value[value.length - 2].value)
            if (value.length > 2)
                setcity(value[value.length - 3].value)
            setcountry(value[value.length - 1].value)
            console.log("abcd", latitude, langitude)
        }
        //setaddress(place.formatted_address)
    }, [value])

    useEffect(() => {
        if (screentype === "create" || screentype === "" || screentype === null) { localStorage.setItem('selectedclientid', ""); }
        if (screentype !== "create") {
            LoadClientDetails_api(localStorage.getItem("selectedclientid")).then(res => {
                const result = res;
                if (result !== undefined) {
                    if (result == null) {
                        //setuserList([])
                    }
                    else {
                        setClientname(result.clientname);
                        setcontactperson(result.contactname)
                        setcontactemail(result.email)
                        setcontactphone(result.contactno)
                        setaddress(result.address)
                        setwebsite(result.website)
                        setcity(result.city)
                        setcountry(result.country)
                        setstate(result.state)
                        setzipcode(result.zipcode)
                        setpersonname(result.contactname)
                        setpersonemail(result.contactemail)
                        setpersonphone(result.contactphone)
                    }
                }
                else {
                }
            }).catch();
        }
    }, [localStorage.getItem("selectedclientid")]);

    const handleValidation = () => {
        let formIsValid = true;
        let errors = {};

        if (Clientname === "" || Clientname === null) {
            formIsValid = false;
            errors["name"] = "Please enter Client name";
        }
        if (contactemail === "" || contactemail === null) {
            formIsValid = false;
            errors["email"] = "Please enter email";
        }
        if (!validateEmail(contactemail) && contactemail !== "" && contactemail !== null) {
            formIsValid = false;
            errors["email"] = "Please enter valid email";
            toast.error("Please enter valid email")
        }
        if (contactphone == "" && contactphone == null) {
            formIsValid = false;
            errors["phone"] = "Please enter valid mobile no.";
            toast.error("Please enter valid mobile no.")
        }
        if (zipcode !== "" && zipcode !== null && zipcode.length < 6) {
            formIsValid = false;
            errors["zipcode"] = "Please enter valid Zipcode";
            toast.error("Please enter valid Zipcode")
        }
        if (!validateEmail(personemail) && personemail !== "" && personemail !== null) {
            formIsValid = false;
            errors["personemail"] = "Please enter valid person email";
            toast.error("Please enter valid person email")
        }
        setError(errors);
        return formIsValid;
    }

    const clearFields = () => {
        console.log("clear")
        setClientname('');
        setcontactphone('');
        setcontactperson('');
        setcontactemail('');
        setaddress('')
        setstate('')
        setwebsite('')
        setcountry('')
        setcity('')
        setzipcode('')
        setlogo('')
        setpersonphone('')
        setpersonname('')
        setpersonemail('')
        setselectedClientid('');
        localStorage.setItem('selectedclientid', "");
    }

    const load = () => {
        clearFields()
    }

    const handleChange = (field, e) => {
        e.preventDefault();
        if (field === "Clientname") {
            const value = e.target.value;
            setClientname(value);
            console.log(Clientname)
        }
        if (field === "contactperson") {
            const value = e.target.value;
            setcontactperson(value);
            console.log(contactperson)
        }
        if (field === "contactphone") {
            const value = e.target.value.replace(/\D/g, "");
            setcontactphone(value);
            console.log(contactphone)
        }
        if (field === "contactemail") {
            const value = e.target.value;
            setcontactemail(value);
        }
        if (field === "website") {
            const value = e.target.value;
            setwebsite(value);
        }
        if (field === "logo") {
            const value = e.target.value;
            setlogo("images/" + value);
        }
        if (field === "address") {
            const value = e.target.value;
            setaddress(value);
        }
        if (field === "city") {
            const value = e.target.value;
            setcity(value);
        }
        if (field === "state") {
            const value = e.target.value;
            setstate(value);
        }
        if (field === "country") {
            const value = e.target.value;
            setcountry(value);
        }
        if (field === "zipcode") {
            const value = e.target.value;
            setzipcode(value);
        }
        if (field === "personemail") {
            const value = e.target.value;
            setpersonemail(value);
        }
        if (field === "personname") {
            const value = e.target.value;
            setpersonname(value);
        }
        if (field === "personphone") {
            const value = e.target.value.replace(/\D/g, "");
            setpersonphone(value);
        }
    }

    const SaveBtnClick = (e) => {
        e.preventDefault();
        if (screentype === 'edit') {
            screentype = "update"
        }
        else {
            console.log("client details",
                Clientname,
                contactemail,
                contactphone,
                website,
                logo,
                address,
                city,
                state,
                country,
                zipcode,
                personname,
                personemail,
                personphone, 22
            )
            if (handleValidation()) {
                var req = {
                    organization: Clientname,
                    email: contactemail,
                    contactno: contactphone,
                    website: website,
                    logo: logo,
                    address: address,
                    city: city,
                    state: state,
                    country: country,
                    zipcode: zipcode,
                    contactname: personname,
                    contactemail: personemail,
                    contactphone: personphone,
                    timezoneid: 22,
                    latitude: latitude.toString(),
                    langitude: langitude.toString(),
                }
                CreateClient_api(req).then(res => {
                    if (res !== undefined) {
                        toast.success(res.message)
                        clearFields();
                        FetchClientDetail();
                        // history.push('/client-list');
                        // <Redirect to="client-list"/>
                    }
                })
                    .catch();

            }
             else {
                window.$("#AddNewCustomer").modal('show');
            }
        }
    }

    const UpdateProject_submit = (e) => {
        e.preventDefault();
        if (handleValidation()) {
            var req = {
                clientid: Number(localStorage.getItem("selectedclientid")),
                organization: Clientname,
                email: contactemail,
                contactno: contactphone,
                website: website,
                logo: "http://mepco.acolabz.com/backend/uploads/logo/acompworld.png",
                address: address,
                city: city,
                state: state,
                country: country,
                zipcode: zipcode,
                contactname: personname,
                contactemail: personemail,
                contactphone: personphone,
                timezoneid: 22
            }
            UpdateClient_api(req).then(res => {
                if (res !== undefined) {
                    toast.success(res.message);
                    FetchClientDetail();
                }
            })
                .catch();

        }
    }

    const onfocusSearch = () => {
        window.$("#searchlocation").attr("autocomplete", "new-password");
    }

    return <div class="modal fade" id="AddNewCustomer">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <Link to="/client-list" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span></Link>
                    {
                        screentype === "create"
                            ?
                            <h5 class="box-title">Add Client</h5>
                            :
                            screentype === "edit"
                                ?
                                <h5 class="box-title">View Client</h5>
                                :
                                <h5 class="box-title">Update Client</h5>
                    }
                </div>
                <div class="modal-body">
                    <div class="add-custmer-form ">
                        <div className="row">
                            <label for="inputEmail3" class="col-sm-3 control-label"><p className="form-control flabel">Client Name <span class="asterisc">*</span></p></label>
                            <div class="col-md-9">
                                <p style={screentype === 'edit' ? { display: 'none' } : { display: 'block' }}><input type="text" class="form-control" id="inputEmail3" placeholder="Enter name" value={Clientname} maxLength="50" onChange={(e) => { handleChange("Clientname", e) }} style={screentype === 'edit' ? { display: 'none' } : { display: 'block' }} /></p>
                                <p className="form-control noborder" style={screentype !== 'edit' ? { display: 'none' } : { display: 'block' }}>{Clientname}</p>
                                <span style={{ color: "red" }}>{errors["name"]}</span>
                            </div>
                        </div>



                        <div className="row">
                            <label for="inputEmail3" class="col-sm-3 control-label"><p className="form-control flabel">Email Id <span class="asterisc">*</span></p></label>
                            <div class="col-md-9">
                                <p style={screentype === 'edit' ? { display: 'none' } : { display: 'block' }}><input type="text" class="form-control" id="inputEmail3" placeholder="Enter email" value={contactemail} maxLength="50" onChange={(e) => { handleChange("contactemail", e) }} style={screentype === 'edit' ? { display: 'none' } : { display: 'block' }} /></p>
                                <p className="form-control noborder" style={screentype !== 'edit' ? { display: 'none' } : { display: 'block' }}>{contactemail}</p>
                                <span style={{ color: "red" }}>{errors["email"]}</span>
                            </div>
                        </div>

                        <div className="row">
                            <label for="inputEmail3" class="col-sm-3 control-label"><p className="form-control flabel">Contact Number</p></label>
                            <div class="col-md-9">
                                <p style={screentype === 'edit' ? { display: 'none' } : { display: 'block' }}><input type="text" class="form-control" id="inputEmail3" placeholder="Enter phone number" value={contactphone} maxLength="10" onChange={(e) => { handleChange("contactphone", e) }} style={screentype === 'edit' ? { display: 'none' } : { display: 'block' }} /></p>
                                <p className="form-control noborder" style={screentype !== 'edit' ? { display: 'none' } : { display: 'block' }}>{contactphone}</p>
                                {/* <span style={{ color: "red" }}>{errors["phone"]}</span> */}
                            </div>
                        </div>

                        <div className="row">
                            <label for="inputEmail3" class="col-sm-3 control-label"><p className="form-control flabel">Website</p></label>
                            <div class="col-md-9">
                                <p style={screentype === 'edit' ? { display: 'none' } : { display: 'block' }}><input type="text" class="form-control" id="inputEmail3" placeholder="Enter website" value={website} maxLength="50" onChange={(e) => { handleChange("website", e) }} style={screentype === 'edit' ? { display: 'none' } : { display: 'block' }} /></p>
                                <p className="form-control noborder" style={screentype !== 'edit' ? { display: 'none' } : { display: 'block' }}>{website}</p>
                                {/* <span style={{ color: "red" }}>{errors["website"]}</span> */}
                            </div>
                        </div>

                        <div className="row">
                            <label for="inputEmail3" class="col-sm-3 control-label"><p className="form-control flabel">Logo</p></label>
                            <div class="col-md-9">
                                <p style={screentype === 'edit' ? { display: 'none' } : { display: 'block' }}><input type="file" class="form-control" id="inputEmail3" maxLength="10" onChange={(e) => { handleChange("logo", e) }} style={screentype === 'edit' ? { display: 'none' } : { display: 'block' }} /></p>
                                <p className="form-control noborder" style={screentype !== 'edit' ? { display: 'none' } : { display: 'block' }}>{logo}</p>
                                {/* <span style={{ color: "red" }}>{errors["logo"]}</span> */}
                            </div>
                        </div>

                        <h5 class="box-title">Address</h5>

                        <div className="row">
                            <label for="inputEmail3" class="col-sm-3 control-label"><p className="form-control flabel">Address</p></label>
                            <div class="col-md-9">
                                <p style={screentype === 'edit' ? { display: 'none' } : { display: 'block' }}>
                                    <Search onSelect={onSelect} setLatitude={setLatitude} setLongitude={setLongitude} />
                                </p>
                                <p className="form-control noborder" style={screentype !== 'edit' ? { display: 'none' } : { display: 'block' }}>{address}</p>
                                {/* <span style={{ color: "red" }}>{errors["address"]}</span> */}
                            </div>
                        </div>

                        <div className="row">
                            <label for="inputEmail3" class="col-sm-3 control-label"><p className="form-control flabel">City</p></label>
                            <div class="col-md-9">
                                <p style={screentype === 'edit' ? { display: 'none' } : { display: 'block' }}><input type="text" class="form-control" id="inputEmail3" autoComplete="nope" placeholder="Enter city" value={city} maxLength="50" onChange={(e) => { handleChange("city", e) }} style={screentype === 'edit' ? { display: 'none' } : { display: 'block' }} /></p>
                                <p className="form-control noborder" style={screentype !== 'edit' ? { display: 'none' } : { display: 'block' }}>{city}</p>
                                {/* <span style={{ color: "red" }}>{errors["city"]}</span> */}
                            </div>
                        </div>

                        <div className="row">
                            <label for="inputEmail3" class="col-sm-3 control-label"><p className="form-control flabel">State</p></label>
                            <div class="col-md-9">
                                {/* <p style={screentype === 'edit' ? { display: 'none' } : { display: 'block' }}><input type="text" class="form-control" id="inputEmail3" placeholder="Enter state" value={state} maxLength="10" onChange={(e) => { handleChange("state", e) }} style={screentype === 'edit' ? { display: 'none' } : { display: 'block' }} /></p> */}
                                <input className="form-control" value={state} onChange={e => handleChange("state", e)} style={screentype === 'edit' ? { display: 'none' } : { display: 'block' }} placeholder="Enter State" />
                                {/* <span style={{ color: "red" }}>{errors["state"]}</span> */}
                            </div>
                        </div>

                        <div className="row">
                            <label for="inputEmail3" class="col-sm-3 control-label"><p className="form-control flabel">Country</p></label>
                            <div class="col-md-9">
                                {/* <p style={screentype === 'edit' ? { display: 'none' } : { display: 'block' }}><input type="text" class="form-control" id="inputEmail3" placeholder="Enter country" value={country} maxLength="50" onChange={(e) => { handleChange("country", e) }} style={screentype === 'edit' ? { display: 'none' } : { display: 'block' }} /></p> */}
                                <input className="form-control" autoComplete="nope" value={country} onChange={e => handleChange("country", e)} style={screentype === 'edit' ? { display: 'none' } : { display: 'block' }} placeholder="Enter Country" />
                                {/* <span style={{ color: "red" }}>{errors["country"]}</span> */}
                            </div>
                        </div>

                        <div className="row">
                            <label for="inputEmail3" class="col-sm-3 control-label"><p className="form-control flabel">Zipcode</p></label>
                            <div class="col-md-9">
                                <p style={screentype === 'edit' ? { display: 'none' } : { display: 'block' }}><input type="text" class="form-control" id="inputEmail3" placeholder="Enter zipcode" value={zipcode} maxLength="6" onChange={(e) => { handleChange("zipcode", e) }} style={screentype === 'edit' ? { display: 'none' } : { display: 'block' }} /></p>
                                <p className="form-control noborder" autoComplete="nope" style={screentype !== 'edit' ? { display: 'none' } : { display: 'block' }}>{zipcode}</p>
                                {/* <span style={{ color: "red" }}>{errors["zipcode"]}</span> */}
                            </div>
                        </div>

                        <h5 class="box-title">Contact Person</h5>

                        <div className="row">
                            <label for="inputEmail3" class="col-sm-3 control-label"><p className="form-control flabel">Name</p></label>
                            <div class="col-md-9">
                                <p style={screentype === 'edit' ? { display: 'none' } : { display: 'block' }}><input type="text" class="form-control" id="inputEmail3" placeholder="Enter name" value={personname} maxLength="50" onChange={(e) => { handleChange("personname", e) }} style={screentype === 'edit' ? { display: 'none' } : { display: 'block' }} /></p>
                                <p className="form-control noborder" style={screentype !== 'edit' ? { display: 'none' } : { display: 'block' }}>{personname}</p>
                                {/* <span style={{ color: "red" }}>{errors["personname"]}</span> */}
                            </div>
                        </div>

                        <div className="row">
                            <label for="inputEmail3" class="col-sm-3 control-label"><p className="form-control flabel">Email Id</p></label>
                            <div class="col-md-9">
                                <p style={screentype === 'edit' ? { display: 'none' } : { display: 'block' }}><input type="text" class="form-control" id="inputEmail3" placeholder="Enter email" value={personemail} maxLength="50" onChange={(e) => { handleChange("personemail", e) }} style={screentype === 'edit' ? { display: 'none' } : { display: 'block' }} /></p>
                                <p className="form-control noborder" style={screentype !== 'edit' ? { display: 'none' } : { display: 'block' }}>{personemail}</p>
                                <span style={{ color: "red" }}>{errors["personemail"]}</span>
                            </div>
                        </div>

                        <div className="row">
                            <label for="inputEmail3" class="col-sm-3 control-label"><p className="form-control flabel">Contact Number</p></label>
                            <div class="col-md-9">
                                <p style={screentype === 'edit' ? { display: 'none' } : { display: 'block' }}><input type="text" class="form-control" id="inputEmail3" placeholder="Enter phone number" value={personphone} maxLength="10" onChange={(e) => { handleChange("personphone", e) }} style={screentype === 'edit' ? { display: 'none' } : { display: 'block' }} /></p>
                                <p className="form-control noborder" style={screentype !== 'edit' ? { display: 'none' } : { display: 'block' }}>{personphone}</p>
                                {/* <span style={{ color: "red" }}>{errors["personphone"]}</span> */}
                            </div>
                        </div>

                        <div class="col-md-12">
                            <button data-dismiss="modal" class="btn btn-theme pull-right">Cancel</button>
                            {
                                screentype === "create"
                                    ?
                                    <button onClick={SaveBtnClick} class="btn btn-theme pull-right" data-dismiss="modal">Save</button>
                                    :
                                    screentype === "edit"
                                        ?
                                        <button onClick={SaveBtnClick} class="btn btn-theme pull-right">Edit</button>
                                        :
                                        <button onClick={UpdateProject_submit} class="btn btn-theme pull-right" data-dismiss="modal">Update</button>
                            }
                            {/* {
                    screentype === "edit"?
                    <Link onClick={(e)=>submit(selectedClientid)} href="" class="btn btntrash "><i class="fa fa-trash"></i></Link>:
                    <Link onClick={ListClients} class="btn btn-gray b-radius "> Cancel</Link>
                  } */}
                            {/* <a type="submit" class="btn btn-theme pull-right" href="#">SAVE </a> */}
                        </div>
                    </div>
                </div>

            </div>
            {/* <!-- /.modal-content --> */}
        </div>
    </div>
}

export default ModalComponent
