import React, { useState, useEffect } from 'react'
import { useHistory, Link, Redirect } from "react-router-dom";
import Header from './wccommon/Header.js';
import Sidebar from './wccommon/sidebar.js';
import { LoadExpiredSubscriptionDetails_api } from '../functions/analytics';

function ClientProfile({clientid}) {
    const history = useHistory();
    const [errors, setError] = useState({});
    const [Clientname, setClientname] = useState("");
    const [contactperson, setcontactperson] = useState("");
    const [contactemail, setcontactemail] = useState("");
    const [contactphone, setcontactphone] = useState("");
    const [website, setwebsite] = useState("");
    const [logo, setlogo] = useState("");
    const [address, setaddress] = useState([]);
    const [city, setcity] = useState([]);
    const [state, setstate] = useState([]);
    const [country, setcountry] = useState([]);
    const [zipcode, setzipcode] = useState([]);
    const [personname, setpersonname] = useState("");
    const [personemail, setpersonemail] = useState("");
    const [personphone, setpersonphone] = useState("");
    const [subscriptions, setsubscriptions] = useState("");
    const [frequentUsers, setfrequentUsers] = useState("");
    const [maxsubscribed, setmaxsubscribed] = useState("");
    const [tickettotal, settickettotal] = useState("");
    const [ticket, setticket] = useState("");

    useEffect(() => {
            LoadExpiredSubscriptionDetails_api(localStorage.getItem("selectedclient")).then(res => {
                const result = res;
                if (result !== undefined) {
                   
                        setClientname(result.clientname);
                        setcontactperson(result.contactname)
                        setcontactemail(result.email)
                        setcontactphone(result.contactno)
                        setaddress(result.address)
                        setwebsite(result.website)
                        setcity(result.city)
                        setcountry(result.country)
                        setstate(result.state)
                        setzipcode(result.zipcode)
                        setpersonname(result.contactname)
                        setpersonemail(result.contactemail)
                        setpersonphone(result.contactphone)
                        setsubscriptions(result.subscribers)
                        setfrequentUsers(result.frequentusers)
                        setmaxsubscribed(result.maxsubscribed)
                        settickettotal(result.totaltickets)
                        setticket(result.ticketthismonth)
                }
                else {
                }
            }).catch();
        }
    , [clientid]);
    

    return (
        <>
            <div class="hold-transition skin-black sidebar-mini">
            <div class="wrapper">
            <Header />
          <Sidebar />
          <div class="content-wrapper db-background">
                {/* <!-- Main content --> */}
                <section class="content content-body">
                {/* <!-- Main row --> */}
                <div class="row">
                <div class="col-md-12">
                <div class="content-header">
                <Link to="/activereport"><img src="images/left-arrow.svg" alt="" style={{ width: "15px" }} /></Link>
                <h1 class="">
                    Customer Profile        
                </h1>
                </div>
                </div>
                </div>



                {/* <div class="box box-solid custop">  */}
                <div class="row">
                <div class="col-md-3">          
                {/* <!-- /.box-header --> */}
                <div class="customerinfo">	
                <h4>Client Details</h4>
                <p>{Clientname}</p>	
                <p>{contactemail}</p>
                <p>{contactphone}</p>
                <p>{website}</p>
                </div>
                </div>
                <div class="col-md-3">          
                {/* <!-- /.box-header --> */}
                <div class="customerinfo">	
                <h4>Address</h4>
                <p>{address}</p><p>{city}</p><p>{state}</p><p>{country}</p>
                </div>
                </div>
                <div class="col-md-3">          
                {/* <!-- /.box-header --> */}
                <div class="customerinfo cp">	
                <h4>Contact Person </h4>
                <p><img src="images/person.png"/> {personname}</p>
                <p><img src="images/phone.png"/> {personphone}</p>
                <p><img src="images/email.png"/> {personemail}</p>	
                </div>
                </div>
                <div class="col-md-3">
                <div class="custlogo">
                <img src="images/logo.svg" alt=""/>
                </div>
                </div>
                </div>
                

                <div class="row">
                <div class="col-md-3">
                <div class="box box-solid">           
                
                <div class="custmerinfobox">	
                    <h3 class="">{subscriptions}</h3>	
                    <h5>Subscriptions</h5>	
                </div>
                </div>
                </div>
                <div class="col-md-3">
                <div class="box box-solid">           
                
                <div class="custmerinfobox">	
                    <h3 class="">{frequentUsers}</h3>	
                    <h5>Frequent Users</h5>	
                </div>
                </div>
                </div>
                <div class="col-md-3">
                <div class="box box-solid">           
                
                <div class="custmerinfobox">	
                    <h3 class="">{maxsubscribed}</h3>	
                    <h5>Max Subscribed</h5>	
                </div>
                </div>
                </div>
                <div class="col-md-3">
                <div class="box box-solid">           
                
                <div class="custmerinfobox">	
                    <h3 class="">{ticket}/{tickettotal}</h3>	
                    <h5>This Month/Total</h5>
                    <h5>Tickets</h5>	
                </div>
                </div>
                </div>
                {/* <div class="col-md-12">
                <div class="custtotal">This Month/Total </div>
                </div> */}
                </div>

                {/* <!-- /.row (main row) --> */}
                </section>
                {/* <!-- /.content --> */}
                </div>
                </div>
            </div>
        </>
    )
}

export default ClientProfile
