import { toast } from 'react-toastify'


const API_Base_Url = process.env.REACT_APP_API_BASE_URL;
var AccessToken = localStorage.getItem("AccessToken");
const loggeduserid = Number(localStorage.getItem("userid"));

//Customer List
export const LoadExpiredSubscription_api = async () => {
    try {
        const response = await fetch("http://20.106.186.30/backend/api/admin/rptExpiredSubscriptions", {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        if (response.status === 401) {
            toast.error('Your Session has been expired, Please login again.');
            // return window.setTimeout(function () {
            //     localStorage.clear();
            //     window.location.href = "/";
            // }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined) {
                toast.error(result.Error.ErrorMessage)
            }
            else {

            }

        }
        else {

        }
    } catch (error) {
        //toast.error('Something went wrong , Please try again later.')
    }

}

export const LoadExpiredSubscriptionDetails_api = async (id) => {
    try {
        const response = await fetch(`http://20.106.186.30/backend/api/admin/rptCustomerProfile?clientid=${id}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })

        if (response.status === 401) {
            toast.error('Your Session has been expired, Please login again.');
            // return window.setTimeout(function () {
            //     localStorage.clear();
            //     window.location.href = "/";
            // }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            console.log("Detail Expire ",result)
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined) {
                toast.error(result.Error.ErrorMessage)
            }
            else {

            }

        }
        else {

        }
    } catch (error) {
        //toast.error('Something went wrong , Please try again later.')
    }

}