//import axios from 'axios'
import { toast } from 'react-toastify'
//import { useSelector } from 'react-redux'

const API_Base_Url = process.env.REACT_APP_API_BASE_URL;

export const LoginApi = async (reqdata) => {
    try {
        //var data = [];
        const response = await fetch(API_Base_Url+"admin/login", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                // 'Authorization': 'Bearer ' + this.state.AccessToken
            },
            body: JSON.stringify({
                "username":reqdata.username,
                "password":reqdata.password
            })
        })
        if (response.status === 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            toast.error(result.errors[0])
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

export const ChangePassword = async (reqdata) => {
    try {
        //var data = [];
        const response = await fetch(API_Base_Url + "users/changepassword", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                 'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify(reqdata)
        })
        if (response.status === 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            toast.error(result.errors[0])
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

export const ResetPassord_api_1 = async (reqdata) => {
    try {
        //var data = [];
        const response = await fetch(API_Base_Url + "users/forgotpassword", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                //'Authorization': 'Bearer ' + this.state.AccessToken
            },
            body: JSON.stringify(reqdata)
        })
        if (response.status === 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            toast.error(result.errors[0])
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

//verify
export const ResetPassord_api_2 = async (reqdata) => {
    try {

        const response = await fetch(API_Base_Url + "users/verifycode", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                //'Authorization': 'Bearer ' + this.state.AccessToken
            },
            body: JSON.stringify(reqdata)
        })
        //alert(response.status)
        if (response.status === 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            toast.error(result.errors[0])
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

//reset password
export const ResetPassord_api_3 = async (reqdata) => {
    try {
        //var data = [];
        const response = await fetch(API_Base_Url + "users/resetpassword", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                //'Authorization': 'Bearer ' + this.state.AccessToken
            },
            body: JSON.stringify(reqdata)
        })
        if (response.status === 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            toast.error(result.errors[0])
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

export const VerifyEmail_api = async (verificationcode) => {
    try {
        //var data = [];
        const response = await fetch(API_Base_Url + "admin/clientverify", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                 'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify(
                {
                    verificationcode:verificationcode
                }
            )
        })
        if (response.status === 401) {
            toast.error('Your Session has been expired, Please login again.');
            // return window.setTimeout(function () {
            //     localStorage.clear();
            //     window.location.href = "/#/";
            // }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result.message;
        }
        else if (response.status === 400) {
            console.log(result.errors[0])
            //toast.error(result.errors[0])
            return result.errors[0];
            
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};



