import { toast } from 'react-toastify'


const API_Base_Url = process.env.REACT_APP_API_BASE_URL;
var AccessToken = localStorage.getItem("AccessToken");
const loggeduserid = Number(localStorage.getItem("userid"));

//Customer List
export const LoadSubscription_api = async (id) => {
    try {
        const response = await fetch(API_Base_Url + `admin/clientSubscriptions?clientid=${id}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        if (response.status === 401) {
            toast.error('Your Session has been expired, Please login again.');
            // return window.setTimeout(function () {
            //     localStorage.clear();
            //     window.location.href = "/";
            // }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            toast.error(result.errors[0])

        }
        else {

        }
    } catch (error) {
        //toast.error('Something went wrong , Please try again later.')
    }

}

export const LoadSubscriptionDetails_api = async (id,subid) => {
    try {
        const response = await fetch(API_Base_Url + `admin/clientSubscriptionDetail?clientid=${id}&subscriptionid=${subid}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })

        if (response.status === 401) {
            toast.error('Your Session has been expired, Please login again.');
            // return window.setTimeout(function () {
            //     localStorage.clear();
            //     window.location.href = "/";
            // }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined) {
                toast.error(result.Error.ErrorMessage)
            }
            else {

            }

        }
        else {

        }
    } catch (error) {
        //toast.error('Something went wrong , Please try again later.')
    }

}

// Add Customers
export const CreateSubscription_api = async (reqdata) => {
    try {
        const response = await fetch(`${API_Base_Url}admin/addClientSubscription`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "clientid":reqdata.clientid,
                "amount":reqdata.amount,
                "allowedusers":reqdata.allowedusers,
                "validtilldate":reqdata.validtilldate


            })
        })
        if (response.status === 401) {
            toast.error('Your Session has been expired, Please login again.');
            // return window.setTimeout(function () {
            //     localStorage.clear();
            //     window.location.href = "/";
            // }, 1000);
        }
        const result = await response.json();
        console.log(result)
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if (!result.status) {
                toast.error("Cannot add")
            }
            else {
                //toast.error(result.errors)
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.' + error)
    }
};

//Update Customer
export const UpdateSubscription_api = async (reqdata) => {
    try {
        const response = await fetch(`${API_Base_Url}admin/updateClientSubscription`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "clientid":reqdata.clientid,
                "subscriptionid":reqdata.subscriptionid,
                "amount":reqdata.amount,
                "allowedusers":reqdata.allowedusers,
                "validtilldate":reqdata.validtilldate
            })
        })
        if (response.status === 401) {
            toast.error('Your Session has been expired, Please login again.');
            // return window.setTimeout(function () {
            //     localStorage.clear();
            //     window.location.href = "/";
            // }, 1000);
        }
        const result = await response.json();
        console.log("Update result", result)
        if (response.ok) {
            return result
        }
        else if (response.status === 400) {
                console.log(result.errors)
                toast.error(result.errors)
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

//Delete Customer
export const DeleteClient_api = async (id) => {
    try {
        const response = await fetch(`${API_Base_Url}admin/clients/delete`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "clientid": id
            })
        })
        if (response.status === 401) {
            toast.error('Your Session has been expired, Please login again.');
            // return window.setTimeout(function () {
            //     localStorage.clear();
            //     window.location.href = "/";
            // }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            toast.success(result.message)
        }
        else if (response.status === 400) {
            if (result.Error !== undefined) {
                toast.error(result.Error.ErrorMessage)
            }
            else {

            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }

};

//Active Not Active status update
export const ChangeClientStatus_api = async (id, status) => {
    try {
        const response = await fetch(`${API_Base_Url}admin/clientstatus`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "clientid": id,
                "status": status
            })
        })
        if (response.status === 401) {
            toast.error('Your Session has been expired, Please login again.');
            // return window.setTimeout(function () {
            //     localStorage.clear();
            //     window.location.href = "/";
            // }, 1000);
        }
        const result = await response.json();
        console.log("sssssss", result)
        if (response.ok) {
            toast.success(result.message)
        }
        else if (response.status === 400) {
            if (result.Error !== undefined) {
                toast.error(result.error)
            }
            else {

            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.' + error)
    }

};