import React, { useEffect, useState } from 'react'
import { Link, useHistory,NavLink } from 'react-router-dom'
import axios from 'axios'
import Header from './wccommon/Header';
import Footer from './wccommon/Footer';
import { confirmAlert } from 'react-confirm-alert';
import Sidebar from './wccommon/sidebar';
import { toast } from 'react-toastify';
import ModalComponent from './ModalComponent';
import { LoadClient_api, ChangeClientStatus_api, DeleteClient_api } from '../functions/client.js'

function ClientList() {

  const history = useHistory();
  const [organisation, setorganisation] = useState([])
  const [statusnum, setstatus] = useState(0)
  const [isLoaded, setIsLoaded] = useState(false);
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [number, setnumber] = useState(0)
  const [open, setOpen] = useState(false);
  const [screentype, setScreentype] = useState("");

  useEffect(() => {

    if (localStorage.getItem("AccessToken") === undefined) {
      history.push("/")
    }

    FetchClientDetail()
    FetchClientDetail();
  }, [offset, number])

  const FetchClientDetail =() => {
    setIsLoaded(false)

    LoadClient_api().then(res => {
      const result = res;
      //console.log("abc",result)
      if (result !== undefined) {
        if (result.data === null) {
          setorganisation([]);
        }
        else {
          setorganisation(result.data);
        }

      }
      else {
        setorganisation([]);
      }
      setIsLoaded(true);
    }).catch();
  }

  console.log("Organisation", organisation)


  const AddClient = () => {
    setScreentype("create");
    localStorage.setItem('selectedclientid', "");
    //window.$("#AddNewCustomer").modal('show');
  }
  const loadCLientDetails = (item) => {
    setScreentype("update");
    localStorage.setItem('selectedclientid', item);
  }

  const updateStatus = (id, status) => {
    var updatedstatus = (status === "Active") ? 0 : 1;
    ChangeClientStatus_api(id, updatedstatus).then(res => {
      if (res !== undefined) {
        toast.success(res.message)
      }
    })
      .catch();
    setnumber(number + 1)
  }

  const GotoClient = (id, or) => {

    localStorage.setItem("selectedclientid", id);
    localStorage.setItem("selectedclient", or);
    history.push('/adminuserlist');

  }

  const GotoSubscription = (id,name) => {
    console.log("Token", id)
    localStorage.setItem("selectedclientid", id);
    localStorage.setItem("selectedclient", name);
    history.push('/adminsubscriptionlist');
  }

  const deleteClient = (clientid) => {
    confirmAlert({
      title: 'Confirmation',
      message: 'Are you sure to detete this client.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {

            DeleteClient_api(clientid).then(res => {
              if (res !== undefined) {
              }
            }).catch();

            FetchClientDetail()
          }
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]
    });
  };

  return (
    <>
      <div className="hold-transition skin-black sidebar-mini">
        <div className="wrapper">
          <Header />
          <Sidebar />
          {/* <!-- Content Wrapper. Contains page content --> */}
          <div class="content-wrapper db-background">
            {/* <!-- Main content --> */}
            <section class="content content-body">
              {/* <!-- Main row --> */}
              <div class="row">
                {/* <!-- Left col 8--> */}
                <div class="col-lg-12">
                  <div class="content-header ">
                    <h1 class="">Client Management</h1>
                    <div class="topbtn-right">
                    <Link data-toggle="modal" data-target="#AddNewCustomer" onClick={(e) => AddClient()} class="btn btn-theme"><i class="fa fa-plus-circle"></i> &nbsp;Add Client</Link>
                    </div>
                  </div>
                  </div>
                  <div class="col-md-12">
                    <div class="top-btn-group "></div>
                  <div class="box box-solid">
                    {/* <div class="box-header with-border">
                    <a type="" class="btn btn-theme ">Supervisor (10)</a>
                        <a type="" class="btn btn-theme ">Account (10)</a>
                        <a type="" class="btn btn-theme ">Technicians (10)</a> 
                      <div class="pull-right">
                      <div class="input-group input-group-sm hidden-xs pull-left serch-fild">
                          <input type="text" name="table_search" class="form-control ih34" placeholder="Search" />
                          <div class="input-group-btn">
                            <button type="" class="btn btn-default ih34"><i class="fa fa-search"></i></button>
                          </div>
                        </div> 
                        
                      </div>
                    </div> */}
                    {/* <!-- /.box-header --> */}
                    <div class="box-body">
                      <table class="table table-bordered table-striped text-center">
                        <tbody><tr>
                          <th>S. No.</th>
                          <th style={{ width: "200px" }}>Client / Organization</th>
                          <th style={{ width: "200px" }}>Address</th>
                          <th style={{ width: "200px" }}>Contact Details</th>
                          <th style={{ width: "200px" }}>Total Users</th>
                          <th style={{ width: "150px" }}>Current Plan</th>
                          <th style={{ width: "150px" }}>Subscription</th>
                          <th style={{ width: "100px" }}>Status</th>
                          <th style={{ width: "50px" }}>Verified Status</th>
                          <th style={{ width: "100px" }}>Operations</th>
                        </tr>
                          {

                            isLoaded === false ? <div class="loader"><img src="images/loader.gif" alt="" /></div>
                              :
                              <>
                                {
                                  organisation.length > 0 ?
                                    organisation.map((data, c = 0) => {
                                      c = c + 1;
                                      var date = new Date(data.CreatedOn);
                                      var month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
                                      return (
                                        <tr key={data.clientid}>
                                          <td>{c}</td>
                                          <td>{data.clientname}<br></br><small>{data.website}</small></td>
                                          <td>{data.address}</td>
                                          <td>{data.contactemail} <br></br> {data.contactno}</td>
                                          <td> <Link onClick={(e) => GotoClient(data.clientid, data.clientname)} class="stsbtn sts-active">Users ({data.totalusers})</Link> </td>
                                          <td style={{ textAlign: "start" }}>Amt : {data.planamount}<br></br>User Allowed: {data.allowedusers}<br></br>Valid Till : {data.validtilldate}</td>
                                          <td><Link class="stsbtn sts-active" style={data.planstatus==="Expired"?{border: '1px solid lightpink',color:"black",backgroundColor:"lightpink"}:{}} onClick={(e) => GotoSubscription(data.clientid,data.clientname)}>Subscription</Link></td>
                                          <td><Link onClick={(e) => updateStatus(data.clientid, data.status)} class="stsbtn sts-active" style={data.status === "Active" ? {} : { backgroundColor: 'lightpink', border: '1px solid lightpink', color: 'black' }}>{data.status === "Active" ? "Active" : "Inactive"}</Link></td>
                                          <td>{data.verifystatus}</td>
                                          <td class="">
                                            <Link data-toggle="modal" data-target="#AddNewCustomer" onClick={(e) => loadCLientDetails(data.clientid)} class="mr-15 od-edit"><i class="fa fa-pencil"></i></Link>
                                            <Link onClick={(e) => deleteClient(data.clientid)} class="od-edit"><i class="fa fa-trash"></i></Link>
                                          </td>
                                        </tr>
                                      )
                                    })
                                    :
                                    (
                                      <tr>
                                        <td colSpan="10">No Data Found</td>
                                      </tr>
                                    )
                                }
                              </>
                          }
                        </tbody></table>
                    </div>
                    {/* <!-- /.box-body --> */}

                  </div>
                </div>



              </div>
              {/* <!-- /.row (main row) --> */}
            </section>
            {/* <!-- /.content --> */}

          </div>
        </div>
        <ModalComponent
          screentype={screentype} FetchClientDetail={FetchClientDetail}/>
        {/* <!-- /.modal-dialog --> */}
      </div>
    </>
  )
}

export default ClientList
